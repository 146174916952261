import React, { useCallback, useRef } from 'react';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications';

import { Link, useHistory } from 'react-router-dom';

import logo from '../../assets/logo.svg';

import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, } from './styles';
import GlobalStyle from '../../styles/global';

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToasts();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (email: string) => {
      try {
        formRef.current?.setErrors({});

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast('Ops! Preencha o email.', { appearance: 'error', autoDismiss: true, });
      }
    },
    [history, addToast],
  );

  return (
    <>
     <Container>
        <Content>
          <AnimationContainer>
            <img src={logo} alt="App Câmbio" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h3>Esqueci minha senha</h3>

              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Button type="submit">Enviar</Button>

              <Link to="/">Voltar para o login</Link>
            </Form>

            <Link to="/">
              <FiLogIn />
              Seja um parceiro
            </Link>
          </AnimationContainer>
        </Content>
      </Container>

      <GlobalStyle theme={true} />
    </>
  );
};

export default ResetPassword;
