import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const Page = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    color: #fff;
  }

  .MuiPaginationItem-ellipsis {
    color: #ccc;
  }

  button.Mui-selected {
    background: #999;
  }
`;
