import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IModal {
  handleCloseModal: any;
  modal: boolean;
  title: String;
  maxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

const Modal: React.FC<IModal> = ({ modal = false, handleCloseModal, title, maxWidth, children }) => {

  const bgColorHeader = {
    color: "white",
    backgroundColor: "#434344",
  };

  const bgColor = {
    color: "white",
    backgroundColor: "#2f3031",
  };

  return (
    <div>
      <Dialog
        open={modal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title" style={bgColorHeader}>{title}</DialogTitle>
        <DialogContent style={bgColor}>
          <DialogContentText id="alert-dialog-description" style={bgColor}>
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Modal;
