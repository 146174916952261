import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${Props => Props.disabled ? css`
    background: #000;
    border: 1px solid #000;
  ` : css`
    background: #1f2021;
    border: 1px solid #1f2021;
  `}

  border-radius: 10px;
  padding: 12px;
  width: 100%;
  color: #eee;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  & + div {
    margin-top: 8px;
  }
  ${(props) =>
    props.isFocused &&
    css`
      color: #3CA372;
      border: 1px solid #333437;
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: #3CA372;
      border: 1px solid #333437;
    `}
  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid #FF7373;
    `}
  svg {
    margin-right: 16px;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #AAAAAA;
    &::placeholder {
      color: #666666;
    }
  }
`;
