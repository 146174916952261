import React, { useCallback, useRef, useEffect, useState } from 'react';

import { useToasts } from 'react-toast-notifications';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

import { maskCnpjOrCpf } from '../../utils/helpers';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';

import SemAvatar from '../../assets/sem-avatar.jpg';

import { Container, Grid, Logo, Heading, GroupButton } from './styles';

interface IStore {
  id: number;
  name: string;
  cnpj: string;
  company: string;
  company_name: string;
  zip_code: string;
  address: string;
  state: string;
  city: string;
  phone: string;
  email: string;
}

interface IFile {
  id: any;
  file: string;
  type: string;
}

const Profile: React.FC = () => {
  const { user, updatedUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [store, setStore] = useState<IStore>({} as IStore);
  const [file, setFile] = useState<IFile>({} as IFile);
  const [loading, setLoading] = useState(true);
  const [loadingStore, setLoadingStore] = useState(true);
  const { addToast } = useToasts();
  let checkRole = user.role.name === 'MODERADOR';

  useEffect(() => {
    async function loadStore() {
      const response = await api.get(`storeuser/${user.store_id}`);

      setStore(response.data);
      setLoading(false);
      setLoadingStore(false);
    }

    loadStore();
  }, [user]);

  const handleAvatar = useCallback(async (e: any) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);

    const response = await api.post('files', data);

    setFile(response.data);
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);

      data['store'] = checkRole;

      if (Object.keys(file).length) {
        data['file_id'] = file.id;
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string().required('Email obrigatório'),
        store_name: Yup.string().required('Nome da Loja obrigatório'),
        company: Yup.string().required('Razão Social obrigatório'),
        company_name: Yup.string().required('Nome Fantasia obrigatório'),
        zip_code: Yup.string().required('Cep obrigatório'),
        address: Yup.string().required('Endereço obrigatório'),
        city: Yup.string().required('Cidade obrigatório'),
        state: Yup.string().required('Estado obrigatório'),
        phone: Yup.string().required('Telefone obrigatório'),
        store_email: Yup.string().required('Email da Loja obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put('storeuser', data);

      addToast('Atualizado com sucesso.', { appearance: 'success', autoDismiss: true, })

      setLoading(false);
      const userUpdate = {
        ...response.data,
        file: Object.keys(file).length ? file : user.file
      };
      updatedUser(userUpdate);
    } catch(err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        setLoading(false);

        return;
      }

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast(err.response.statusText, { appearance: 'error', autoDismiss: true, });
      }

      setLoading(false);
    }
  }, [addToast, checkRole, file, updatedUser, user]);

  return (
    <Layout
      nav="profile"
      breadcumb={`Meu Perfil`}
      loading={loading}
    >
      <Container>
        <div className="top"></div>

        <Logo>
          <label>
            {Object.keys(file).length ? (
              <img src={file.file} alt={user.name} />
            ) : (
              <>
               {user.file ? (
                 <img src={user.file.file} alt={user.name} />
               ) : (
                  <img src={SemAvatar} alt={user.name} />
               )}
              </>
            )}

            <input type="file" name="file" onChange={handleAvatar} />
            <input
              type="file"
              id="avatar"
              accept="image/*"
              onChange={handleAvatar}
            />

            <div>
              <p>Edit</p>
            </div>
          </label>
          <p>{user.name}</p>
        </Logo>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Card>
            <Grid>
                <div>
                  <p>Nome:</p>
                  <Input name="name" defaultValue={user.name} />
                </div>
                <div>
                  <p>Email:</p>
                  <Input name="email" defaultValue={user.email} />
                </div>
            </Grid>
          </Card>

          <Heading>Alterar Senha</Heading>

          <Card>
            <Grid>
                <div>
                  <p>Nova Senha:</p>
                  <Input type="password" name="password" />
                </div>
                <div>
                  <p>Repetir Senha:</p>
                  <Input type="password" name="password_repeat" />
                </div>
            </Grid>
          </Card>

          <Heading>Editar Loja</Heading>

          <Card>
            {!loadingStore && (
              <Grid>
                <div>
                  <p>Nome</p>
                  <Input
                    type="text"
                    name="store_name"
                    defaultValue={String(store.name)}
                    disabled={!checkRole}
                  />
                </div>
                <div>
                  <p>CNPJ:</p>
                  <Input
                    type="text"
                    name="cnpj"
                    disabled
                    defaultValue={maskCnpjOrCpf(store.cnpj)}
                  />
                </div>
                <div>
                  <p>Razão Social:</p>
                  <Input
                    type="text"
                    name="company"
                    disabled={!checkRole}
                    defaultValue={String(store.company)}
                  />
                </div>
                <div>
                  <p>Nome Fantasia:</p>
                  <Input
                    type="text"
                    name="company_name"
                    disabled={!checkRole}
                    defaultValue={String(store.company_name)}
                  />
                </div>
                <div>
                  <p>Cep:</p>
                  <Input
                    type="text"
                    name="zip_code"
                    id="cep"
                    mask="cep"
                    disabled={!checkRole}
                    defaultValue={String(store.zip_code)}
                  />
                </div>
                <div>
                  <p>Endereço:</p>
                  <Input
                    type="text"
                    name="address"
                    disabled={!checkRole}
                    defaultValue={String(store.address)}
                  />
                </div>
                <div>
                  <p>Cidade:</p>
                  <Input
                    type="text"
                    name="city"
                    disabled={!checkRole}
                    defaultValue={String(store.city)}
                  />
                </div>
                <div>
                  <p>Estado:</p>
                  <Input
                    type="text"
                    name="state"
                    disabled={!checkRole}
                    defaultValue={String(store.state)}
                  />
                </div>
                <div>
                  <p>Telefone:</p>
                  <Input
                    type="text"
                    name="phone"
                    disabled={!checkRole}
                    defaultValue={String(store.phone)}
                  />
                </div>
                <div>
                  <p>Email:</p>
                  <Input
                    type="text"
                    name="store_email"
                    disabled={!checkRole}
                    defaultValue={String(store.email)}
                  />
                </div>
            </Grid>
            )}
          </Card>

          <GroupButton>
            <Button type="submit" color="default" size="large">
              ATUALIZAR
            </Button>
          </GroupButton>
        </Form>

      </Container>
    </Layout>
  );
}

export default Profile;
