import React from 'react';

import { MdBuild, MdMonetizationOn, MdPhoneAndroid } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { useHistory, Link } from 'react-router-dom';

import Layout from '../../components/Layout';

import { Container, GridContainer } from './styles';

const Settings: React.FC = () => {
  const history = useHistory();

  return (
    <Layout
      nav="settings"
      breadcumb={`Configurações`}
    >
      <Container>
        <div className="top">
          <h2>Configurações</h2>
        </div>

        <GridContainer>
          <Link to="/" className="grid-setting">
            <div>
              <MdBuild size={42} />
              <p>Geral</p>
              <small>Configurações geral do sistema como: notificações, links e acesso.</small>
            </div>
          </Link>

          <Link to="/setting/exchange" className="grid-setting">
            <div>
              <MdMonetizationOn size={42} />
              <p>Cotações de Câmbio</p>
              <small>Ajustar a cotação do câmbio da sua loja. Altere os preços do original.</small>
            </div>
          </Link>

          <Link to="/" className="grid-setting">
            <div>
              <MdPhoneAndroid size={42} />
              <p>SMS</p>
              <small>Visualizar e alterar texto do envio SMS.</small>
            </div>
          </Link>

          <Link to="/" className="grid-setting">
            <div>
              <FaWhatsapp size={42} />
              <p>Whatsapp</p>
              <small>Configurar o seu whatsapp para utilizar o envio.</small>
            </div>
          </Link>
        </GridContainer>
      </Container>
    </Layout>
  );
}

export default Settings;
