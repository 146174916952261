import React, { useEffect, useState } from 'react';

import {
  MdAttachMoney,
  MdWork,
  MdShoppingCart,
  MdTrendingUp,
  MdPerson,
  MdBusinessCenter
} from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { FaWhatsapp } from 'react-icons/fa';

import Flag from 'react-flagkit';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';

import ChartLine from '../../components/Charts/line';
import TypograhySkeleton from '../../components/Skeleton/typography';
import SkeletonGrid from '../../components/Skeleton/skeletongrid';

import {
  GridColumn,
  GridColumnTwo,
  Content,
  Chart,
  Icon,
  GridContainer,
  GridCurrence,
  GridColumnLoading,
} from './styles';

import api from '../../services/api';

interface ISales {
  id: any;
  coin_id: number;
  tax_coin_formatted: string;
  buy_coin_formatted: string;
  sale_formatted: string;
  type_sale: string;
  coin: {
    icon: string;
  }
}

interface ICustomers {
  id: any;
  full_name: String;
  email: String;
  phone_whatsapp: String;
  fiscal_number: String;
}

interface ITotalSale {
  totalsale: String;
  totalbuy: String;
  totalBuySaleCount: Number;
}

interface ICoins {
  name: any;
  icon: String;
  value: Number;
  conversion_rates: Number;
  money_formatted: String;
  money_formatted_conversion: String;
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const Dashboard: React.FC = () => {
  const [sales, setSales] = useState<ISales[]>([] as ISales[]);
  const [customers, setCustomers] = useState<ICustomers[]>([] as ICustomers[]);
  const [coins, setCoins] = useState<ICoins[]>([] as ICoins[]);
  const [customersTotal, setCustomersTotal] = useState(0);
  const [loadCustomers, setLoadCustomers] = useState(true);
  const [loadCarousel, setLoadCarousel] = useState(true);
  const [loadSales, setLoadSales] = useState(true);
  const [totalSale, setTotalSale] = useState<ITotalSale>({
    totalsale: '0,00',
    totalbuy: '0,00',
    totalBuySaleCount: 0
  } as ITotalSale);
  const [salesMonth, setSalesMonth] = useState([0, 0, 0, 0, 0]);
  const [buyMonth, setBuyMonth] = useState([0, 0, 0, 0, 0]);
  const history = useHistory();

  useEffect(() => {
    api.get('dashboard/sales')
      .then(response => {
        setSales(response.data);
        setLoadSales(false);
    }).catch(err => {
      console.log(err.message);
      setLoadSales(false);
    });

    api.get('dashboard/customers')
      .then(response => {
        setCustomers(response.data);
        setLoadCustomers(false);
    }).catch(err => {
      console.log(err.message);
      setLoadCustomers(false);
    });

    api.get('dashboard/totalsales')
      .then(response => {
        setTotalSale(response.data);

        const salesGraphic = response.data.salesGraphic;
        const buyGraphic = response.data.buyGraphic;
        const dataFormattedSales = salesGraphic.map((sale: any) => sale.value);
        const dataFormattedBuy = buyGraphic.map((buy: any) => buy.value);

        setSalesMonth(dataFormattedSales);
        setBuyMonth(dataFormattedBuy);
    }).catch(err => console.log(err.message));

    api.get('exchangeapi?array=true')
      .then(response => {
        setCoins(response.data);
        setLoadCarousel(false);
    }).catch(err => console.log(err.message));

    api.get('dashboard/totalcustomers')
      .then(response => {
        setCustomersTotal(response.data);
    }).catch(err => console.log(err.message));
  }, []);

  return (
    <Layout nav="" breadcumb="">
      <GridColumn>
        <Card>
          <Content>
            <Icon bgcolor="#3CA372">
              <MdAttachMoney />
            </Icon>

            <div className="bloco">
              <small>Venda Realizada (Dia)</small>
              <div><span>R$</span><h3>{totalSale.totalsale}</h3></div>
            </div>
          </Content>
        </Card>

        <Card>
          <Content>
            <Icon bgcolor="#296EF5">
              <MdShoppingCart />
            </Icon>

            <div className="bloco">
              <small>Compra Realizada (Dia)</small>
              <div><span>R$</span><h3>{totalSale.totalbuy}</h3></div>
            </div>
          </Content>
        </Card>

        <Card>
          <Content>
            <Icon bgcolor="#008C8C">
              <MdTrendingUp />
            </Icon>

            <div className="bloco">
              <small>Quantidade de Venda e Compra (Dia)</small>
              <h3>{totalSale.totalBuySaleCount}</h3>
            </div>
          </Content>
        </Card>

        <Card>
          <Content>
            <Icon bgcolor="#FF9326">
              <MdWork />
            </Icon>

            <div className="bloco">
              <small>Quantidade de Clientes</small>
              <h3>{customersTotal}</h3>
            </div>
          </Content>
        </Card>
      </GridColumn>

      <GridCurrence>
        {loadCarousel ? (
          <GridColumnLoading>
            <SkeletonGrid grid={7} />
          </GridColumnLoading>
        ) : (
          <Carousel
            responsive={responsive}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            removeArrowOnDeviceType={["desktop", "mobile"]}
          >
            {coins.map(coin => {
              return (
                <div key={coin.name} className="currence">
                  <div className="icon-flag">
                    <Flag country={String(coin.icon)} size={42} style={{ marginTop: -8, marginLeft: -8 }} />
                  </div>
                  <div className="money">
                    <span>{coin.name}</span>
                    <p>{coin.money_formatted}</p>
                    <small>{coin.money_formatted_conversion}</small>
                  </div>
                </div>
              );
            })}
          </Carousel>
        )}
      </GridCurrence>

      <GridContainer>
        <Card>
          <Chart>
            <ChartLine
              datasales={salesMonth}
              databuy={buyMonth}
              bgColor="#42B883"
              label="Venda"
            />
          </Chart>
        </Card>
      </GridContainer>

      <GridColumnTwo>
        <Card>
          <h4 className="titleCard">Clientes Recentes</h4>
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Nome</td>
                  <td>CPF / CNPJ</td>
                  <td>Telefone</td>
                </tr>
              </thead>
              <tbody>
                {loadCustomers ? (
                  <TypograhySkeleton col={4} line={10} />
                ) : (
                  <>
                    {customers.map(customer => {
                      return (
                        <tr key={customer.id}>
                          <td>{customer.id}</td>
                          <td>{customer.full_name}</td>
                          {customer.fiscal_number.length > 14 ? (
                            <td>
                              <div className="table-icon">
                                <MdBusinessCenter /> {customer.fiscal_number}
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="table-icon">
                                <MdPerson /> {customer.fiscal_number}
                              </div>
                            </td>
                          )}
                          <td>
                            <div className="table-icon">
                              <FaWhatsapp color="#59B200" /> {customer.phone_whatsapp}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
        </Card>
        <Card>
          <h4 className="titleCard">Vendas ou Compras Recentes</h4>
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>moeda</td>
                  <td>Tipo</td>
                  <td>Taxa</td>
                  <td>Valor Moeda</td>
                  <td>Valor Total</td>
                  <td>Ação</td>
                </tr>
              </thead>
              <tbody>
                {loadSales ? (
                  <TypograhySkeleton col={6} line={10} />
                ) : (
                  <>
                    {sales.map(item => {
                      return (
                        <tr key={item.id}>
                          <td width="10%">
                            <div className="icon-flag-small">
                              <Flag country={item.coin.icon} size={32} style={{ marginTop: -6, marginLeft: -6 }} />
                            </div>
                          </td>
                          <td>{item.type_sale}</td>
                          <td>{item.tax_coin_formatted}</td>
                          <td>{item.coin.icon} {item.buy_coin_formatted}</td>
                          <td>{item.sale_formatted}</td>
                          <td align="right">
                            <button
                              type="button"
                              className="btn-details"
                              onClick={() => history.push(`/sale/${item.id}`, item)}
                            >
                              Detalhes
                            </button>
                          </td>
                      </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
        </Card>
      </GridColumnTwo>
    </Layout>
  );
};

export default Dashboard;
