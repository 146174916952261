import styled from 'styled-components';
import { shade } from 'polished';

export const GridForm = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 6px;
  margin-top: 6px;
  font-size: 12px;

  div {
    span {
      margin-left: 8px;
    }
  }

  .groupform {
    width: 100%;

    .money {
      display: block;
      width: 100%;
      background: #fff;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: center;
      color: #49B382;
    }

    label {
      margin-left: 6px;
      margin-bottom: 6px;
    }

    & + .groupform {
      margin-top: 12px;
    }
  }
`;

export const Block = styled.ul`
  display: block;
  list-style: none;

  li {
    list-style: none;
    width: 100%;

    button {
      background: none;
      border: none;
      width: 100%;
      color: #eee;
      text-align: left;
      background: #444444;
      border-radius: 7px;
      padding: 8px 14px;

      &:hover {
        background: #49B382;
      }
    }

    & + li {
      margin-top: 6px;
    }
  }
`;

export const ButtonGrid = styled.div`
  display: block;
  margin-top: 12px;

  button {
    width: 100%;
    border: none;
    color: #fff;
  }

  .cancelar {
    background: #FF4D4D;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      background: ${shade(0.2, '#FF4D4D')};
    }
  }

  .salvar {
    background: #49B382;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      background: ${shade(0.2, '#49B382')};
    }
  }
`;

export const SwitchGrid = styled.div`
  display: flex;
  width: auto;
`;
