import React, { useCallback, useState, useEffect } from 'react';
import Echo from 'laravel-echo';

import api from '../../services/api';

import { Scrollbars } from 'react-custom-scrollbars';
import useOnclickOutside from "react-cool-onclickoutside";

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { MdNotifications, MdMoreVert } from 'react-icons/md';

import { Link } from 'react-router-dom';

import { Container, User, Notifications, Loading } from './styles';

import { useAuth } from '../../hooks/auth';
import { useNotification } from '../../hooks/notification';

import Breadcumb from '../MaterialUi/breadcumb';

import semAvatar from '../../assets/sem-avatar.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(2),
      },
    },
  }),
);

const defaultProps = {
  color: 'secondary' as 'secondary',
  children: <MdNotifications size={26} />,
};

interface IBreadcumb {
  breadcumb: String;
}

interface INotifications {
  id: any;
  type: string;
  title: string;
  notification: string;
  user_id: string;
  user_notification: string;
  store_id: number;
  customer_id: number;
  created_at: string;
}

const echo = new Echo({
  broadcaster: 'pusher',
  key: '9d2462ca0a07721144a2',
  cluster: 'us2',
  forceTLS: true,
});

const Header: React.FC<IBreadcumb> = ({ breadcumb }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { countNotification, handleNotificationCount } = useNotification();

  const [notifications, setNotifications] = useState<INotifications[]>([] as INotifications[]);
  const [notification, setNotification] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [loadNotification, setLoadNotification] = useState(true);

  useEffect(() => {
    handleNotificationCount();
  }, [handleNotificationCount]);

  const handleNotification = useCallback((event: any) => {
    handleNotificationCount();

    if (user.id !== event.user_id) {
      setNotification(event.notification);
      setOpen(true);
    }
  }, [handleNotificationCount, user]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const ref = useOnclickOutside(() => {
    setOpenNotification(false);
  });

  const handleOpenNotification = useCallback(async () => {
    setOpenNotification(!openNotification);

    try {
      setLoadNotification(true);
      const response = await api.get('notifications', {
        params: {
          type: 'dashboard'
        }
      });

      setNotifications(response.data);
      handleNotificationCount();
      setLoadNotification(false);
    } catch(err) {
      setLoadNotification(false);
    }
  }, [openNotification, handleNotificationCount]);

  echo
    .channel(`my-channel-notification`)
    .listen(`.my-event-notification`, (event: any) => {
      handleNotification(event);
    });

  return (
    <>
    <Container>
      <Breadcumb breadcumb={breadcumb} />

      <User>
        <div className="notification">
          <div className={classes.root}>
            <button type="button" onClick={handleOpenNotification} ref={ref}>
              <Badge badgeContent={countNotification} {...defaultProps} />
            </button>
          </div>

          <Notifications active={openNotification}>
            <div className="notification-messages">
              <div id="triangulo-para-cima"></div>
              {loadNotification && (
                <Loading>
                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </Loading>
              )}
              <Scrollbars className="scrollbar" style={{ height: 300 }}>
                  <ul>
                    {notifications.map(noti => {
                      return(
                        <li key={noti.id}>
                          <h4>{noti.title}</h4>
                          <span>{noti.notification}</span>
                          <small>Data: 25 de março de 2020 as 13:00h</small>
                        </li>
                      );
                    })}
                  </ul>
              </Scrollbars>
            </div>
          </Notifications>
        </div>

        <div className="avatar">
          {user.name}
          <small>{user.email}</small>
        </div>

        {user.file ? (
          <img src={user.file.file} alt={user.name} />
        ) : (
          <img src={semAvatar} alt={user.name} />
        )}

        <Link to="/profile"><MdMoreVert /></Link>
      </User>
    </Container>

    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={12000}
        onClose={handleClose}
        message={`Novo Cliente: ${notification}`}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              Fechar
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default Header;
