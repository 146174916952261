import React from 'react';

import { Line } from 'react-chartjs-2';

import pt from 'date-fns/locale/pt';
import { setMonth, getMonth, subMonths, getYear, subYears, format } from 'date-fns';

const getMonthFourSub: Number = Number(format(new Date(subMonths(new Date(), 4)), 'MM')) - 1;
const getMonthTreeSub: Number = Number(format(new Date(subMonths(new Date(), 3)), 'MM')) - 1;
const getMonthTwoSub: Number = Number(format(new Date(subMonths(new Date(), 2)), 'MM')) - 1;
const getMonthOneSub: Number = Number(format(new Date(subMonths(new Date(), 1)), 'MM')) - 1;
const getMonthActual: Number = Number(getMonth(new Date()));
const getYearActual = getYear(new Date());
const getYearSub = format(new Date(subYears(new Date(), 1)), 'yyyy');

const date = [
  {
    year: getMonthFourSub > getMonthActual ? getYearSub : getYearActual,
    month: getMonthFourSub
  },
  {
    year: getMonthTreeSub > getMonthActual ? getYearSub : getYearActual,
    month: getMonthTreeSub
  },
  {
    year: getMonthTwoSub > getMonthActual ? getYearSub : getYearActual,
    month: getMonthTwoSub
  },
  {
    year: getMonthOneSub > getMonthActual ? getYearSub : getYearActual,
    month: getMonthOneSub
  },
  {
    year: getYearActual,
    month: getMonthActual
  }
];

const monthFormatted = date.map(d => {
  const month = format(setMonth(new Date(), Number(d.month)), 'MMMM', {
    locale: pt,
  });
  const object = `${month} - ${d.year}`;
  return object;
});

interface IChart {
  datasales: any;
  databuy: any;
  bgColor: String;
  label: String;
}

const LineContainer: React.FC<IChart> = ({ datasales, databuy, bgColor, label }) => {
  const state = {
    labels: monthFormatted,
    datasets: [
      {
        label: `${label} R$`,
        fill: false,
        lineTension: 0.5,
        backgroundColor: bgColor,
        borderColor: bgColor,
        borderWidth: 3,
        data: datasales
      },
      {
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#2693FF',
        borderColor: '#2693FF',
        borderWidth: 3,
        data: databuy
      },
    ]
  }

  return (
    <Line
        data={state}
        options={{
          responsive: true,
          animation: {
            duration: 800, // general animation time
            easing: 'easeInQuad'
          },
          maintainAspectRatio: false,
          legend:{
            display:false,
            position:'right'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value: any, index: any, values: any) {
                  if(parseInt(value) >= 1000){
                    return 'R$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return 'R$ ' + value;
                  }
                }
              }
            }]
          }
        }}
    />
  );
};

export default LineContainer;
