import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 220px;
  border-top: 2px solid #3CA372;
`;

export const Content = styled.div`
  display: block;
  height: 100vh;
  width: 100%;
  max-width: 220px;
  position: fixed;

  button {
    width: 100%;
    position: absolute;
    bottom: 0;
    border: 0;
    background: #FF4D4D;
    text-align: center;
    color: #fff;
    padding: 12px 0;
  }
`;

export const Logo = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;

  img {
    width: 60%;
  }
`;

export const Nav = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;

  .active a {
      background: #2b2c2f;
  }

  li {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #2b2c2f;
    align-items: center;

    svg {
      font-size: 22px;
      color: #3CA372;
    }

    span {
      width: 32px;
      display: flex;
    }

    a {
      display: flex;
      flex: 1;
      color: #fff;
      padding: 12px 14px;
      transition: background-color 0.2s;

      &:hover {
        background: #2b2c2f;
      }
    }
  }
`;
