import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import { useParams } from 'react-router-dom';
import Flag from 'react-flagkit';

import { Container, Grid } from './styles';
import GlobalStyle from '../../styles/global';

import logo from '../../assets/logo.svg';

interface IRates {
  id: any;
  rate_buy: number;
  rate_sale: number;
  rate_sale_formatted: string;
  rate_buy_formatted: string;
  coin_id: number;
  coin: any;
  precision: number;
  store_id: number;
  updatedAtFormatted: Date;
}

const Rates: React.FC = () => {
  const { qrcode }: any = useParams();
  const [rates, setRates] = useState<IRates[]>([] as IRates[]);

  useEffect(() => {
    async function loadRates() {
      try {
        const response = await api.get(`qrcode/${qrcode}`);

        setRates(response.data);
      } catch(err) {
        //
      }
    }

    setInterval(() => {
      loadRates();
    }, 25000);

    loadRates();
  }, [qrcode]);

  return (
    <>
    <Container>
      <div className="logo">
          <img src={logo} alt="App Câmbio" />
      </div>
      <Grid>
          {rates.map(rate => {
            return(
              <div key={rate.id} className="input-exchange">
                <div className="icon-flag-big">
                  <Flag country={rate.coin.icon} size={75} style={{ marginTop: -10, marginLeft: -10 }} />
                </div>
                <p>{rate.coin.name}</p>
                  <div className="currency-input">
                    <label>Venda</label>
                    <div className="inputtext">
                      {rate.rate_sale_formatted}
                    </div>
                  </div>
                  <div className="currency-input">
                    <label>Compra</label>
                    <div className="inputtext">
                      {rate.rate_buy_formatted}
                    </div>
                  </div>
              </div>
            );
          })}
        </Grid>
    </Container>

    <GlobalStyle theme={true} />
    </>
  );
}

export default Rates;
