import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Form } from '@unform/web';

import { useToasts } from 'react-toast-notifications';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Modal from '../../../components/MaterialUi/modal';
import Backdrop from '../../../components/MaterialUi/backdrop';
import Switch from '../../../components/MaterialUi/switch';

import { GridForm, Block, ButtonGrid, SwitchGrid } from './styles';

import getValidationErrors from '../../../utils/getValidationErrors';
import { stringToMoneyNumber, moneyFormat } from '../../../utils/helpers';

interface ISale {
  modal?: boolean;
  handleCloseModal?: any;
}

interface ICustomer {
  id: any;
  full_name: string;
}

interface IRates {
  id: any;
  rate_buy: number;
  rate_sale: number;
  rate_sale_formatted: string;
  rate_buy_formatted: string;
  coin_id: number;
  coin: {
    id: any;
    name: string;
    icon: string;
    coin: string;
  };
  precision: number;
  store_id: number;
  createdAtFormatted: string;
  type?: string;
}

interface ISaleCoin {
  customer_id: string;
  coin: string;
  tax_coin: string;
  type_sale: string;
  buy_coin: string;
}

const ModalSale: React.FC<ISale> = ({ modal = false, handleCloseModal }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [search, setSearch] = useState('name');
  const [btnSearch, setBtnSearch] = useState<ICustomer>({} as ICustomer);
  const [customers, setCustomers] = useState<ICustomer[]>([] as ICustomer[]);
  const [checkedSms, setCheckedSms] = useState(false);
  const [checkedWhatsapp, setCheckedWhatsapp] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState<IRates[]>([] as IRates[]);
  const [coin, setCoin] = useState<IRates>({} as IRates);
  const [sumCoin, setSumCoin] = useState<string>('R$ 0,00');
  const { addToast } = useToasts();

  const handleSearchSale = useCallback(async ({ name }) => {
    setLoading(true);
    const response = await api.get('dashboard/customers', {
      params: {
        name
      }
    });
    setLoading(false);

    setCustomers(response.data);
  }, []);

  const handleChangeCoin = useCallback(async (event) => {
    const { value } = event.target;
    const data: IRates | any = rates.find(rate => rate.id === Number(value));

    const type_sale: any = document.querySelector('#type_sale');
    const buy_coin_document: any = document.querySelector('.buy_coin');

    setCoin({
      ...data,
      type: type_sale.value
    });

    if (buy_coin_document.value) {
      const coinIcon = buy_coin_document.value.substr(0, 3);
      let buy_coin = stringToMoneyNumber(buy_coin_document.value, coinIcon);

      let sumSale = 0;

      if (type_sale.value === 'VENDA') {
        sumSale = data.rate_sale * buy_coin;
      }

      if (type_sale.value === 'COMPRA') {
        sumSale = data.rate_buy * buy_coin;
      }

      setSumCoin(moneyFormat(sumSale));
    }
  }, [rates]);

  const handleSaleSubmit = useCallback(async (event: ISaleCoin) => {

    const data = {
      ...event,
      sale: stringToMoneyNumber(sumCoin),
      sms: checkedSms,
      whatsapp: checkedWhatsapp
    };

    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        type_sale: Yup.string().required('Campo Tipo obrigatório'),
        coin: Yup.string().required('Campo Moeda obrigatório'),
        tax_coin: Yup.string().required('Campo Cotação obrigatória'),
        buy_coin: Yup.string().required('Campo Valor obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const icon_coin = event.buy_coin.substr(0, 3);

      await api.post('sales', {
        ...data,
        buy_coin: stringToMoneyNumber(event.buy_coin, icon_coin),
        coin: icon_coin
      });

      addToast('Venda Realizada com sucesso.', { appearance: 'success', autoDismiss: true, });

      history.push('dashboard');
    } catch(err) {
      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }
    }

  }, [history, sumCoin, checkedSms, addToast, checkedWhatsapp]);

  const handleOnBlurMoney = useCallback((event: any) => {
    const tax_coin_document: any = document.querySelector('#money_venda');
    const tax_coin = stringToMoneyNumber(tax_coin_document.value);
    const buy_coin = stringToMoneyNumber(event.target.value, coin.coin.coin);

    const sumSale = tax_coin * buy_coin;

    setSumCoin(moneyFormat(sumSale));
  }, [coin]);

  const handleOnBlurTaxCoin = useCallback((event: any) => {
    const buy_coin_document: any = document.querySelector('.buy_coin');

    if (buy_coin_document.value) {
      const tax_coin = stringToMoneyNumber(event.target.value);
      const buy_coin = stringToMoneyNumber(buy_coin_document.value, coin.coin.coin);

      const sumSale = tax_coin * buy_coin;

      setSumCoin(moneyFormat(sumSale));
    }
  }, [coin]);

  const handleChangeType = useCallback((event) => {
    const type = event.target.value;

    if (Object.keys(coin).length > 0) {
      const buy_coin_document: any = document.querySelector('.buy_coin');
      const buy_coin = stringToMoneyNumber(buy_coin_document.value, coin.coin.coin);
      let sumSale = 0;

      if (type === 'VENDA') {
        sumSale = coin.rate_sale * buy_coin;
      }

      if (type === 'COMPRA') {
        sumSale = coin.rate_buy * buy_coin;
      }

      setSumCoin(moneyFormat(sumSale));

      setCoin({
        ...coin,
        type
      });
    }
  }, [coin]);

  useEffect(() => {
    async function loadRates() {
      const response = await api.get('rates');

      setRates(response.data);
    }

    loadRates();
  }, []);

  return (
    <>
      <Modal
        modal={modal}
        handleCloseModal={handleCloseModal}
        title="Comprar ou Vender Moeda"
        maxWidth="sm"
      >
        {Object.keys(btnSearch).length === 0 && (
          <>
          <GridForm>
            <div>
              <span>
                  <input
                    type="radio"
                    name="searchtipo"
                    onClick={() => setSearch('name')}
                    defaultChecked={search === 'name'}
                  />
                    Nome / Razão Social
                  </span>
              <span>
                <input
                  type="radio"
                  name="searchtipo"
                  onClick={() => setSearch('cpf')}
                  defaultChecked={search === 'cpf'}
                />
                  CPF
                </span>
              <span>
                <input
                  type="radio"
                  name="searchtipo"
                  onClick={() => setSearch('cnpj')}
                  defaultChecked={search === 'cnpj'}
                />
                  CNPJ
                </span>
            </div>
          </GridForm>
        <Form onSubmit={handleSearchSale}>
          <GridForm>
            {search === 'name' && (
              <Input name="name" placeholder="Pesquisar por Nome / Razão Social" />
            )}
            {search === 'cpf' && (
              <Input name="fiscal_number" placeholder="Pesquisar por CPF" mask="cpf" id="cpf" />
            )}
            {search === 'cnpj' && (
              <Input name="fiscal_number" placeholder="Pesquisar por CNPJ" mask="cnpj" id="cnpj" />
            )}
          </GridForm>
        </Form>

        <Block>
          {customers.map(customer => {
            return (
              <li key={customer.id}>
                <button onClick={() => setBtnSearch(customer)}>{customer.full_name}</button>
              </li>
            );
          })}
        </Block>
        </>
        )}

        {Object.keys(btnSearch).length > 0 && (
          <Form ref={formRef} onSubmit={handleSaleSubmit}>
            <GridForm>
              <div className="groupform">
                <label>Cliente</label>
                <Select name="customer_id" disabled>
                  <option value={btnSearch.id}>{btnSearch.full_name}</option>
                </Select>
              </div>

              <div className="groupform">
                <label>Tipo</label>
                <Select name="type_sale" id="type_sale" onChange={handleChangeType}>
                  <option value="VENDA">VENDA</option>
                  <option value="COMPRA">COMPRA</option>
                </Select>
              </div>

              <div className="groupform">
                <label>Moeda</label>
                <Select name="coin" onChange={handleChangeCoin}>
                  <option value="">...</option>
                  {rates.map(rate => {
                    return (
                      <option key={rate.id} value={`${rate.id}`}>{rate.coin.name}</option>
                    );
                  })}
                </Select>
              </div>

              <div className="groupform">
                <label>Cotação da Moeda</label>
                  {coin.id ? (
                    <>
                    {coin.type === 'VENDA' ? (
                      <Input
                        name="tax_coin"
                        id="money_venda"
                        mask="money_venda"
                        precision={coin.precision}
                        value={coin.rate_sale_formatted}
                        onBlur={handleOnBlurTaxCoin}
                      />
                    ) : (
                      <Input
                        name="tax_coin"
                        id="money_venda"
                        mask="money_venda"
                        precision={coin.precision}
                        value={coin.rate_buy_formatted}
                        onBlur={handleOnBlurTaxCoin}
                      />
                    )}
                    </>
                  ) : (
                    <Input name="tax_coin" id="money_venda" mask="money_venda" />
                  )}
              </div>

              <div className="groupform">
                <label>Valor da moeda</label>
                <Input
                  name="buy_coin"
                  className="buy_coin"
                  id="money_value"
                  mask="money_value"
                  symbol={Object.keys(coin).length > 0 ? coin.coin.coin : 'R$'}
                  onBlur={handleOnBlurMoney}
                  disabled={Object.keys(coin).length === 0}
                />
              </div>

              <div className="groupform">
                <div className="money">Valor total à pagar: {sumCoin}</div>
              </div>
            </GridForm>

            <p>Enviar recibo via:</p>

            <SwitchGrid>
              <Switch
                handleChangeSwitche={() => setCheckedSms(!checkedSms)}
                checked={checkedSms}
                label="SMS:"
              />

              <Switch
                handleChangeSwitche={() => setCheckedWhatsapp(!checkedWhatsapp)}
                checked={checkedWhatsapp}
                label="WHATSAPP:"
              />
            </SwitchGrid>

            <ButtonGrid>
              <button className="salvar">Salvar</button>
            </ButtonGrid>
          </Form>
        )}

        <ButtonGrid>
          <button className="cancelar" onClick={handleCloseModal}>Cancelar</button>
        </ButtonGrid>

        <Backdrop loading={loading} />
      </Modal>
    </>
  );
};

export default ModalSale;
