import VMasker from 'vanilla-masker';
import { format, parseISO } from 'date-fns';

export function stringToMoneyNumber(value: any, symbol: string = 'R$') {
  let valueFormatted: any = '';
  valueFormatted = value.replace(symbol, '');
  valueFormatted = valueFormatted.replace('.', '');
  valueFormatted = Number(valueFormatted.replace(',', '.'));

  return valueFormatted;
}

export function moneyFormat(value: any) {
  let valueFormatted = value.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"});

  return valueFormatted;
}

export function maskCelular(value: any) {
  let valueFormatted = VMasker.toPattern(`${value}`, "(99) 99999-9999");
  return valueFormatted;
}

export function maskCnpjOrCpf(value: any) {
  let valueFormatted = '';
  let fiscalNumber = stringNumber(value);

  if (fiscalNumber.length > 11) {
    valueFormatted = VMasker.toPattern(`${fiscalNumber}`, "99.999.999/9999-99");
  } else {
    valueFormatted = VMasker.toPattern(`${fiscalNumber}`, "999.999.999-99");
  }
  return valueFormatted;
}

export function stringNumber(value: string) {
  return value.replace(/[^\d]+/g, '');
}

export function formatDateFromBrforUs(value: string) {
  const date = value.split('/');

  const dateParseIso = parseISO(`${date[2]}-${date[1]}-${date[0]}`);

  const dateFormate = format(new Date(dateParseIso), 'yyyy-MM-dd');
  return dateFormate;
}
