import React, { useCallback, useEffect, useState, useRef } from 'react';

import api from '../../../services/api';

import { MdKeyboardBackspace } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import Flag from 'react-flagkit';

import Layout from '../../../components/Layout';
import Input from '../../../components/Input';
import Button from '../../../components/Button/custom';
import SkeletonGrid from '../../../components/Skeleton/skeletongrid';

import { Container, Grid, GridCurrence, GridColumnLoading, GridButton } from './styles';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

interface ICoins {
  name: any;
  icon: string;
  value: number;
  conversion_rates: number;
  money_formatted: string;
  money_formatted_conversion: string;
}

interface IRates {
  id: any;
  rate_buy: number;
  rate_sale: number;
  rate_sale_formatted: string;
  rate_buy_formatted: string;
  coin_id: number;
  coin: any;
  precision: number;
  store_id: number;
  updatedAtFormatted: Date;
}

const Exchange: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [coins, setCoins] = useState<ICoins[]>([] as ICoins[]);
  const [rates, setRates] = useState<IRates[]>([] as IRates[]);
  const [updated, setUpdated] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [loadCarousel, setLoadCarousel] = useState(true);
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    api.get('rates')
      .then(response => {

        if (response.data.length === 0) {
          history.push('/setting/edit/exchange');
          return;
        }

        const firstRate = response.data[0];

        setUpdated(firstRate.updatedAtFormatted);
        setRates(response.data);
        setLoading(false);
    }).catch(err => console.log(err.message));

    api.get('exchangeapi?array=true')
      .then(response => {
        setCoins(response.data);
        setLoadCarousel(false);
    }).catch(err => console.log(err.message));
  }, [history]);

  const handleSubmit = useCallback((event) => {
    let data: any[] = [];
    let dataSales: any[] = [];
    let eventsSales = event.rates_sale;
    let eventsBuy = event.rates_buy;

    for (var key in eventsSales) {
      const sale = {
        id: key,
        sale: eventsSales[key]
      };
      dataSales.push(sale);
    }

    for (var keyData in eventsBuy) {
      const getSale = dataSales.find((sale: any) => sale.id === keyData); //eslint-disable-line
      const buy = {
        id: keyData,
        buy: eventsBuy[keyData],
        sale: getSale.sale
      };
      data.push(buy);
    }

    const lastData = data.slice(-1)[0];
    setLoading(true);

    data.forEach(async (e) => {
      await api.put(`rates/${e.id}`, {
        rate_sale: e.sale,
        rate_buy: e.buy
      });

      if (e.id === lastData.id) {
        setLoading(false);
        addToast('Atualizado com sucesso.', { appearance: 'success', autoDismiss: true, });
      }
    });
  }, [addToast]);

  const handleGenerateQrCode = useCallback(async () => {
    setLoadingQrCode(true);
    const response = await api.post('qrcode');
    setLoadingQrCode(false);
    window.open(response.data.file, '_blank');
  }, []);

  return (
    <>
    <Layout
      nav="settings"
      breadcumb={`Configurações,Cotações de Câmbio`}
      loading={loading}
    >
      <Container>
        <div className="top">
          <h2>Cotações de Câmbio (Compra e Venda)</h2>

          <div>
            <Button
              color="gray"
              size="large"
              onClick={() => history.goBack()}
            >
              <MdKeyboardBackspace /> Voltar
            </Button>

            <Button
              color="default"
              size="large"
              onClick={() => history.push('/setting/edit/exchange')}
            >
              Editar Grupo
            </Button>

            <Button
              color="primary"
              size="large"
              onClick={handleGenerateQrCode}
            >
              {loadingQrCode ? 'Carregando...' : 'Gerar QrCode'}
            </Button>
          </div>
        </div>

        <GridCurrence>
          {loadCarousel ? (
            <GridColumnLoading>
              <SkeletonGrid grid={7} />
            </GridColumnLoading>
          ) : (
            <Carousel
              responsive={responsive}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              removeArrowOnDeviceType={["desktop", "mobile"]}
            >
              {coins.map(coin => {
                return (
                  <div key={coin.name} className="currence">
                    <div className="icon-flag">
                      <Flag country={coin.icon} size={42} style={{ marginTop: -8, marginLeft: -8 }} />
                    </div>
                    <div className="money">
                      <span>{coin.name}</span>
                      <p>{coin.money_formatted}</p>
                      <small>{coin.money_formatted_conversion}</small>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          )}
        </GridCurrence>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="createdAt">Atualização: {updated}</div>
        <Grid>
          {rates.map(rate => {
            return(
              <div key={rate.id} className="input-exchange">
                <div className="icon-flag-big">
                  <Flag country={rate.coin.icon} size={75} style={{ marginTop: -10, marginLeft: -10 }} />
                </div>
                <p>{rate.coin.name}</p>
                  <div className="currency-input">
                    <label>Venda</label>
                    <Input
                      type="text"
                      name={`rates_sale[${rate.id}]`}
                      id={`money_${rate.id}_sale`}
                      mask={`money_${rate.id}_sale`}
                      precision={rate.precision}
                      defaultValue={rate.rate_sale_formatted}
                    />
                  </div>
                  <div className="currency-input">
                    <label>Compra</label>
                    <Input
                      type="text"
                      name={`rates_buy[${rate.id}]`}
                      id={`money_${rate.id}_buy`}
                      mask={`money_${rate.id}_buy`}
                      precision={rate.precision}
                      defaultValue={rate.rate_buy_formatted}
                    />
                  </div>
              </div>
            );
          })}
        </Grid>

        <GridButton>
          <Button type="submit" color="default" size="large">
            Atualizar
          </Button>
        </GridButton>
        </Form>
      </Container>
    </Layout>
    </>
  );
}

export default Exchange;
