import React, { useState, useRef, useCallback, useEffect } from 'react';

import { MdKeyboardBackspace, MdModeEdit, MdAttachFile } from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { useToasts } from 'react-toast-notifications';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import AlertDelete from '../../components/Alert/delete';

import { maskCnpjOrCpf } from '../../utils/helpers';

import CpfForm from './include/cpf';
import CnpjForm from './include/cnpj';

import { Container, GridButton } from './styles';

interface IEvent {
  sex: string;
  full_name: string;
  nickname: string;
  civil_status: string;
  exterior: string;
  name_dad: string;
  name_mother: string;
  identity_id: string;
  identity_emission: Date;
  identity_state: string;
  identity_organ: string;
  passport_number: string;
  passport_emission: Date;
  passport_nation: string;
  email: string;
  phone: string;
  phone_whatsapp: string;
  fiscal_number: string;
  birth: Date;
  zip_code: string;
  address: string;
  address_number: string;
  address_complement: string;
  naturalness: string;
  nationality: string;
  neighborhood: string;
  state: string;
  city: string;
}

interface IFiles {
  id: any;
  file: string;
  type: string;
  name: string;
  size: number;
  updated_at: string;
  created_at: string;
}

const Edit: React.FC = () => {
  const location = useLocation();
  const state = Object(location.state);
  const formRef = useRef<FormHandles>(null);
  const { fiscalNumber }: any = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const [formData, setFormData] = useState<IEvent>({} as IEvent);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState('client');
  const [files, setFiles] = useState<IFiles[]>([] as IFiles[]);
  const [openConfirmed, setOpenConfirmed] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const uploadsFiles = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    async function loadFormData() {
      const response = await api.get(`customers/${fiscalNumber}`);

      setFormData(response.data);
      setLoading(false);
    }

    loadFormData();
  }, [fiscalNumber]);

  useEffect(() => {
    async function loadFiles() {
      const response = await api.get(`files/store/${state.id}`);

      setFiles(response.data);
    }

    loadFiles();
  }, [state.id])

  const handleUploads = useCallback(async () => {
    setLoading(true);
    const { lastModified } = acceptedFiles.slice(-1)[0];

    acceptedFiles.forEach(async (file) => {
      try {
        const data = new FormData();
        data.append('file', file);

        const response = await api.post(`files/store/${state.id}`, data);

        files.push(response.data);

        if (lastModified === file.lastModified) {
          setLoading(false);
          acceptedFiles.length = 0
          acceptedFiles.splice(0, acceptedFiles.length);
          addToast('Upload feito com sucesso.', { appearance: 'success', autoDismiss: true, });
        }
      } catch(err) {
        console.log(err.response);
      }
    });
  }, [files, acceptedFiles, addToast, state.id]);

  const handleSubmit = useCallback(async (data: IEvent) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);

      if (fiscalNumber.length > 11) {
        const schema = Yup.object().shape({
          full_name: Yup.string().required('Nome Completo obrigatório'),
          birth: Yup.string().required('Nascimento obrigatória'),
          fiscal_number: Yup.string().required('CPF obrigatória'),
          phone_whatsapp: Yup.string().required('Celular obrigatória'),
          zip_code: Yup.string().required('CEP obrigatória'),
          address: Yup.string().required('Endereço obrigatória'),
          address_number: Yup.string().required('Número do endereço obrigatória'),
          neighborhood: Yup.string().required('Bairro obrigatória'),
          state: Yup.string().required('Estado obrigatória'),
          city: Yup.string().required('Cidade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } else {
        const schema = Yup.object().shape({
          full_name: Yup.string().required('Nome Completo obrigatório'),
          birth: Yup.string().required('Nascimento obrigatória'),
          sex: Yup.string().required('Sexo obrigatória'),
          civil_status: Yup.string().required('Estado Civil obrigatória'),
          name_mother: Yup.string().required('Nome da mãe obrigatória'),
          fiscal_number: Yup.string().required('CPF obrigatória'),
          identity_id: Yup.string().required('RG Número obrigatória'),
          phone_whatsapp: Yup.string().required('Celular obrigatória'),
          zip_code: Yup.string().required('CEP obrigatória'),
          address: Yup.string().required('Endereço obrigatória'),
          address_number: Yup.string().required('Número do endereço obrigatória'),
          neighborhood: Yup.string().required('Bairro obrigatória'),
          state: Yup.string().required('Estado obrigatória'),
          city: Yup.string().required('Cidade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      const response = await api.put(`customers/${fiscalNumber}`, {
        ...data,
        type: fiscalNumber.length > 11 ? 'cnpj' : 'cpf',
      });

      if (response.data.error) {
        addToast(response.data.error, { appearance: 'warning', autoDismiss: true, });
        return;
      }

      setFormData(response.data);
      addToast('Cliente atualizado com sucesso.', { appearance: 'success', autoDismiss: true, });
      setLoading(false);

    } catch(err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        setLoading(false);

        return;
      }

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }

      setLoading(false);
    }
  }, [fiscalNumber, addToast]);

  const handleCep = useCallback(async (event: any) => {
    const cep = event;
    const response = await api.get(`cep/${cep}`);

    setFormData({
      ...formData,
      address: response.data.address,
      neighborhood: response.data.district,
      state: response.data.state
    });
  }, [formData]);

  const handleDeleteOrCancel = useCallback(async (event: any) => {
    if (event === 'confirmed') {
      setOpenConfirmed(true);
      return;
    }

    if (event === 'cancel') {
      setOpenConfirmed(false);
      return;
    }

    try {
      setLoadingDelete(true);
      await api.delete(`customers/${fiscalNumber}`);

      addToast('Cliente deletado com sucesso.', { appearance: 'success', autoDismiss: true, });

      history.push('/customers');
    } catch(err) {
      setLoadingDelete(false);

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }
    }
  }, [fiscalNumber, addToast, history]);

  return (
    <>
    <Layout
      nav="customers"
      breadcumb={`Clientes,Editar,${state.full_name}`}
      loading={loading}
    >
      <Container>
        <div className="top">
        <h2>Cadastro de Cliente - Pessoa {fiscalNumber.length > 11 ? 'Jurídica' : 'Física'} / {maskCnpjOrCpf(fiscalNumber)}</h2>

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>

        <ul className="navigation-edit">
          <li>
            <button
              type="button"
              onClick={() => setStep('client')}
              className={step === 'client' ? 'active' : ''}
            >
              <MdModeEdit /> Dados do cliente
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setStep('upload')}
              className={step === 'upload' ? 'active' : ''}
            >
              <MdAttachFile /> Upload de Documentos
            </button>
          </li>
        </ul>

        {step === 'client' ? (
          <Card>
            {fiscalNumber.length > 11 ? (
              <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
                <CnpjForm
                  form={formData}
                  handleCep={handleCep}
                  handleDeleteOrCancel={() => handleDeleteOrCancel('confirmed')}
                  type="ATUALIZAR"
                />
              </Form>
            ) : (
              <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
                <CpfForm
                  form={formData}
                  handleCep={handleCep}
                  type="ATUALIZAR"
                />
              </Form>
            )}
          </Card>
        ) : (
          <>
            <Card>
              <section className="section">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p>Arraste e solte alguns arquivos aqui ou clique para selecionar arquivos.</p>
                </div>
                {uploadsFiles.length > 0 && (
                  <>
                  <aside>
                    <h4>Arquivos para Upload</h4>
                    <ul>{uploadsFiles}</ul>
                  </aside>
                  <GridButton>
                    <Button type="submit" color="default" size="large" onClick={handleUploads}>
                        Carregar
                    </Button>
                  </GridButton>
                  </>
                 )}
              </section>
            </Card>

            {files.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <Card>
                  <section className="section">
                    <aside>
                      <ul>
                        {files.map((file) => {
                          return(
                            <li key={file.id}>
                              <div>{file.name} - {file.size} bytes</div>
                              <a href={file.file} target="blank">Visualizar</a>
                            </li>
                          );
                        })}
                      </ul>
                    </aside>
                  </section>
                </Card>
              </div>
            )}
          </>
        )}
      </Container>
    </Layout>

    <AlertDelete
      open={openConfirmed}
      loading={loadingDelete}
      handleDeleteOrCancel={handleDeleteOrCancel}
    />
    </>
  );
};

export default Edit;
