import styled from 'styled-components';

interface IIcon {
  bgcolor: any;
}

export const Container = styled.div`
  display: block;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #2B2C2F;
    padding-top: 12px;
    padding-bottom: 12px;

    .search {
      display: flex;
      flex: 1;
      width: 100%;

      form {
        width: 96%;
      }
    }

    .btn {
      display: flex;

      button + button {
        margin-left: 5px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

  .bloco {
    display: flex;
    text-align: right;
    flex-direction: column;

    div {
      display: flex;
      align-items: baseline;
      text-align: right;
      justify-content: flex-end;

      span {
        margin-right: 6px;
        font-size: 22px;
      }
    }

    h3 {
      font-size: 32px;
      margin: 0;
    }
  }
`;

export const IconCoin = styled.div`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  border: 2px solid #fff;
`;

export const Icon = styled.div<IIcon>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: ${Props => Props.bgcolor};

  svg {
    font-size: 32px;
  }
`;

export const GridDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;
