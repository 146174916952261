import React from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  switchBase: {
    color: "#999",
    "&$checked": {
      color: "#43B680"
    },
    "&$checked + $track": {
      backgroundColor: "#43B680"
    }
  },
  checked: {},
  track: {}
});

interface ISwitche {
  checked?: boolean;
  handleChangeSwitche: any;
  label?: String;
}

const Switches: React.FC<ISwitche> = ({ checked = false, handleChangeSwitche, label }) => {
  const classes = useStyles();

  return (
    <div className="switch">
      {label && (
        <small>{label} </small>
      )}

      <Switch
        checked={checked}
        onChange={handleChangeSwitche}
        name="checked"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        classes={classes}
      />
    </div>
  );
}

export default Switches;
