import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #2B2C2F;
    padding-top: 12px;
    padding-bottom: 12px;

    .search {
      display: flex;
      flex: 1;
      width: 100%;

      form {
        width: 96%;
      }
    }

    .btn {
      display: flex;

      button + button {
        margin-left: 5px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;

  p {
    padding-left: 8px;
    padding-bottom: 6px;
  }
`;

export const Logo = styled.div`
  display: block;
  width: 100%;
  height: 160px;
  text-align: center;

  img {
    width: 100%;
  }

  label {
    display: block;
    margin: 0px auto;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    border: 4px solid #3CA372;
    background: #fff;
    position: relative;
    overflow: hidden;

    .carrega {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #eee;
    }
    input[type='file'] {
      display: none;
    }
    &:hover {
      color: #fff;
      div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
  }

  p {
    font-size: 15px;
    margin-top: 4px;
  }
`;

export const Heading = styled.div`
  display: block;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 22px;
  color: #ccc;
`;

export const GroupButton = styled.div`
  display: block;
  width: 100%;
  padding-top: 14px;

  button {
    width: 100%;

    & + button {
      margin-top: 12px;
    }
  }
`;
