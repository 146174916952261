import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #2B2C2F;
    padding-top: 12px;
    padding-bottom: 12px;

    div {
      display: flex;

      button + button {
        margin-left: 4px;
      }
    }

    .search {
      display: flex;
      flex: 1;
      width: 100%;

      form {
        width: 96%;
      }
    }

    .btn {
      display: flex;

      button + button {
        margin-left: 5px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
  width: 100%;

  .input-exchange {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 6px;

    p {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .icon-flag {
      margin-right: 6px;
    }

    .createdAt {
      display: block;
      font-size: 11px;
      color: #eee;
      margin-bottom: 12px;
    }

    .currency-input {
      display: block;
      width: 90%;

      & + .currency-input {
        margin-top: 4px;
      }

      label {
        font-size: 12px;
        margin-bottom: 3px;
      }

    }
  }
`;

export const GridColumnLoading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 9px;
`;

export const GridCurrence = styled.div`
  display: block;
  margin-bottom: 24px;

  li {
    padding-left: 4px;
    padding-right: 4px;
  }

  .currence {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: #252629;
    align-items: center;
    padding: 4px 8px;
    color: #3CA372;

    & + .currence {
      margin-left: 8px;
    }
  }

  .money {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      font-size: 11px;
      color: #fff;
    }

    p {
      font-size: 18px;
      font-weight: bold;
    }

    small {
      color: #FF8000;
    }
  }
`;

export const GridButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;

  button + button {
    margin-top: 12px;
  }
`;
