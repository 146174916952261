import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';

interface NotificationContextData {
  countNotification: number;
  handleNotificationCount(): void;
}

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<number>(0);

  const handleNotificationCount = useCallback(async (count = 0) => {
    const response = await api.get('notifications?type=count');

    setNotification(response.data);
  }, []);

  return (
    <NotificationContext.Provider value={{ countNotification: notification, handleNotificationCount }}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(' useNotification must be used within an authProvider ');
  }
  return context;
}

export { NotificationProvider, useNotification };
