import styled from 'styled-components';

interface IIcon {
  bgcolor: any;
}

export const GridColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  margin-bottom: 24px;
`;

export const GridColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const GridContainer = styled.div`
  display: block;
  width: 100%;
`;

export const GridColumnLoading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 9px;
`;

export const GridCurrence = styled.div`
  display: block;
  margin-bottom: 24px;

  li {
    padding-left: 4px;
    padding-right: 4px;
  }

  .currence {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background: #252629;
    align-items: center;
    padding: 4px 8px;
    color: #3CA372;

    & + .currence {
      margin-left: 8px;
    }
  }

  .money {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      font-size: 11px;
      color: #fff;
    }

    p {
      font-size: 18px;
      font-weight: bold;
    }

    small {
      color: #FF8000;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

  .bloco {
    display: flex;
    text-align: right;
    flex-direction: column;

    div {
      display: flex;
      align-items: baseline;
      text-align: right;

      span {
        margin-right: 6px;
        font-size: 22px;
      }
    }

    h3 {
      font-size: 32px;
      margin: 0;
    }
  }
`;

export const Chart = styled.div`
  display: block;
  width: 100%;
  height: 300px;
`;

export const Icon = styled.div<IIcon>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: ${Props => Props.bgcolor};

  svg {
    font-size: 32px;
  }
`;
