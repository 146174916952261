import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import { Page } from './styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

interface IPage {
  page: Number;
  defaultPage: Number;
  handlePage: any;
}

const BasicPagination: React.FC<IPage> = ({ page, handlePage, defaultPage }) => {
  const classes = useStyles();

  return (
    <Page>
      {Number(page) > 1 && (
        <div className={classes.root}>
          <Pagination count={Number(page)} defaultPage={Number(defaultPage)} onClick={handlePage} />
        </div>
      )}
    </Page>
  );
}

export default BasicPagination;
