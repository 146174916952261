import React from 'react';

import Navigation from '../Navigation';
import Header from '../Header';
import Backdrop from '../MaterialUi/backdrop';

import { Container, Grid, Body, Content } from './styles';

import GlobalStyle from '../../styles/global';

interface IBreadcumb {
  breadcumb: String;
  nav: String;
  loading?: boolean;
}

const Layout: React.FC<IBreadcumb> = ({ children, breadcumb, nav, loading = false }) => {

  return (
    <>
      <Container>
        <Grid>
          <Navigation nav={nav} />

          <Body>
            <Header breadcumb={breadcumb} />

            <Content>
              {children}

              <div className="footer">
                by fabiomoraes.dev | v1.0.0
              </div>
            </Content>
          </Body>
        </Grid>
      </Container>

      <Backdrop loading={loading} />
      <GlobalStyle theme={true} />
    </>
  );
};

export default Layout;
