import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

interface ISkeleton {
  col: Number;
  line: Number;
}

const SkeletonTypography: React.FC<ISkeleton> = ({ col, line }) => {
  const [lineArray, ] = useState(() => {
    const array: any = [];

    for (let i = 0; i < line; i++) {
      array.push(i);
    }
    return array;
  });
  const [colArray, ] = useState(() => {
    const array: any = [];

    for (let i = 0; i < col; i++) {
      array.push(i);
    }
    return array;
  });

  return (
    <>
      {lineArray.map((l: any) => {
        return (
          <tr key={l}>
            {colArray.map((c: any) => <td key={c}><Typography component="div"><Skeleton /></Typography></td>)}
          </tr>
        );
      })}
    </>
  );
}

export default SkeletonTypography;
