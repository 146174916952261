import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
import { Body } from './styles/styles';

import AppProvider from './hooks';

const App: React.FC = () => (
  <Body>
    <ToastProvider>
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>

    </Router>
    </ToastProvider>
  </Body>
);

export default App;
