import React, { useEffect, useState } from 'react';

import { MdSearch, MdShoppingCart, MdPerson } from 'react-icons/md';
import { Form } from '@unform/web';
import Flag from 'react-flagkit';

import { useHistory, useLocation } from 'react-router-dom';

import api from '../../services/api';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import Input from '../../components/Input';
import TypograhySkeleton from '../../components/Skeleton/typography';
import Paginate from '../../components/MaterialUi/paginate';

import Modal from './include/modal';

import { Container } from './styles';

interface ISales {
  id: any;
  coin_id: number;
  tax_coin_formatted: string;
  buy_coin_formatted: string;
  sale_formatted: string;
  type_sale: string;
  created_at_formatted: string;
  coin: {
    icon: string;
  };
  customer: {
    full_name: string;
  };
}

const Sales: React.FC = () => {
  const params = useLocation();
  const history = useHistory();

  const [sales, setSales] = useState<ISales[]>([] as ISales[]);
  const [page, setPage] = useState({
    last_page: 1,
    current_page: Number(params.search.replace(/[^0-9]/g,''))
  });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadSales() {
      setLoading(true);
      const response = await api.get(`sales?page=${page.current_page}`);

      setPage({
        last_page: response.data.last_page,
        current_page: response.data.current_page
      });
      setSales(response.data.data);
      setLoading(false);
    }

    loadSales();
  }, [page.current_page]);

  const handleSearch = () => {};

  const handleCloseModal = () => {
    setModal(false);
  };

  const handlePage = async (e: any) => {
    history.push(`?page=${e.target.innerText}`);

    setPage({
      ...page,
      current_page: Number(e.target.innerText)
    });
  };

  return (
    <>
      <Layout
        nav="sales"
        breadcumb="Vendas"
      >
        <Container>
          <div className="top">
            <div className="search">
              <Form onSubmit={handleSearch}>
                  <Input
                    name="search"
                    icon={MdSearch}
                    type="text"
                    placeholder="Pesquisar por Nome / Razão Social"
                  />
              </Form>
            </div>
            <div className="btn">
              <Button size="large" color="gray"><MdSearch /> PESQUISA AVANÇADA</Button>
              <Button size="large" onClick={() => setModal(!modal)}><MdShoppingCart /> VENDER OU COMPRAR</Button>
            </div>
          </div>

          <Card>
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>Cliente</td>
                  <td>Moeda</td>
                  <td>Tipo</td>
                  <td>Taxa</td>
                  <td>Valor da Moeda</td>
                  <td>Valor Total</td>
                  <td>Data</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TypograhySkeleton col={8} line={10} />
                ) : (
                  <>
                    {sales.map(sale => {
                      return (
                        <tr key={sale.id}>
                          <td><MdPerson /> {sale.customer.full_name}</td>
                          <td>
                            <div className="icon-flag-small">
                              <Flag country={sale.coin.icon} size={32} style={{ marginTop: -6, marginLeft: -6 }} />
                            </div>
                          </td>
                          <td>{sale.type_sale}</td>
                          <td>{sale.tax_coin_formatted}</td>
                          <td>{sale.coin.icon} {sale.buy_coin_formatted}</td>
                          <td>{sale.sale_formatted}</td>
                          <td>{sale.created_at_formatted}</td>
                          <td align="right">
                            <button
                              type="button"
                              className="btn-details"
                              onClick={() => history.push(`/sale/${sale.id}`, sale)}
                            >
                              Detalhes
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </Card>

          <Paginate page={page.last_page} defaultPage={page.current_page} handlePage={handlePage} />
        </Container>
      </Layout>

      <Modal
        modal={modal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default Sales;
