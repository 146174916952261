import React from 'react';

import Button from '../Button/custom';

import { Body, Container } from './styles';

interface IAlert {
  handleDeleteOrCancel?: any;
  open?: boolean;
  loading?: boolean;
}

const AlertDelete: React.FC<IAlert> = ({ handleDeleteOrCancel, open = false, loading = false }) => {
  return(
    <Body open={open}>
      <Container>
        <h4>Você realmente deseja excluir?</h4>
        <div className="bloco">
          <Button
            color="default"
            size="large"
            onClick={() => handleDeleteOrCancel('cancel')}
          >
            Cancelar
          </Button>
          <Button
            color="danger"
            size="large"
            onClick={() => handleDeleteOrCancel('')}
          >
            {loading ? 'Carregando...' : 'Deletar'}
          </Button>
        </div>
      </Container>
    </Body>
  );
};

export default AlertDelete;
