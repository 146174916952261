import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #1f2021;
  border: 1px solid #1f2021;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  color: #eee;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  & + div {
    margin-top: 8px;
  }
  ${(props) =>
    props.isFocused &&
    css`
      color: #3CA372;
      border: 1px solid #333437;
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: #3CA372;
      border: 1px solid #333437;
    `}
  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid #FF7373;
    `}
  svg {
    margin-right: 16px;
  }
  select {
    flex: 1;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    background: transparent;
    border: 0;
    color: #AAAAAA;
    &::placeholder {
      color: #666666;
    }

    option {
      background: #1f2021;
      font-size: 12px;
    }
  }
`;
