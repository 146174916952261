import React, { useCallback, useEffect, useState, useRef } from 'react';

import { MdKeyboardBackspace } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useParams, useHistory } from 'react-router-dom';

import api from '../../services/api';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import Select from '../../components/Select';
import Input from '../../components/Input';

import { Container, Grid, GroupButton } from './styles';

interface IUsers {
  id: any;
  name: string;
  email: string;
  role_id: number;
  created_at: string;
}

const EditUser: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [user, setUser] = useState<IUsers>({} as IUsers);

  useEffect(() => {
    async function loadUser() {
      const response = await api.get(`users/${id}`);

      setUser(response.data);
    }

    loadUser();
  }, [id]);

  const handleSubmit = useCallback(() => {}, []);

  return (
    <Layout
      nav="sales"
      breadcumb={`Usuário,Editar,${user.name}`}
    >
      <Container>
        <div className="top">
          <h2>Usuário / {user.name}</h2>

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Card>
            <Grid>
                <div>
                    <p>Nome:</p>
                    <Input name="name" defaultValue={user.name} />
                  </div>
                <div>
                  <p>Email:</p>
                  <Input name="email" defaultValue={user.email} />
                </div>
                <div>
                  <p>Permissão:</p>
                  <Select name="role_id" value={user.role_id}>
                    <option value="">...</option>
                    <option value="2">ADMINISTRADOR</option>
                    <option value="3">OPERADOR</option>
                  </Select>
                </div>
                <div>
                  <p>Alterar Senha:</p>
                  <Input name="password" />
                </div>
            </Grid>
          </Card>

          <GroupButton>
            <Button type="submit" color="default" size="large">
              ATUALIZAR
            </Button>
          </GroupButton>
        </Form>
      </Container>
    </Layout>
  );
}

export default EditUser;
