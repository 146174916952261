import React, { useEffect, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { MdSearch, MdPerson, MdBusinessCenter } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { Form } from '@unform/web';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import Input from '../../components/Input';
import TypograhySkeleton from '../../components/Skeleton/typography';

import api from '../../services/api';

import { Container } from './styles';

interface ICustomers {
  id: any;
  full_name: string;
  email: string;
  phone: string;
  phone_whatsapp: string;
  fiscal_number: string;
  fiscal_number_formatted: string;
  birth: string;
  zip_code: string;
  address: string;
  state: string;
  city: string;
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomers[]>([] as ICustomers[]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function loadCustomers() {
      const response = await api.get('/customers');

      setCustomers(response.data.data);
      setLoading(false);
    }

    loadCustomers();
  }, []);

  const handleSearch = useCallback(({ search }: any) => {
    console.log(search);
  }, []);

  const handlePage = (event: any) => {
    history.push(`/customer/create?type=${event}`);
  };

  return (
    <Layout nav="customers" breadcumb="Clientes">
      <Container>
        <div className="top">
          <div className="search">
            <Form onSubmit={handleSearch}>
                <Input
                  name="search"
                  icon={MdSearch}
                  type="text"
                  placeholder="Pesquisar por Nome / Razão Social"
                />
            </Form>
          </div>
          <div className="btn">
            <Button size="large" color="gray"><MdSearch /> PESQUISA AVANÇADA</Button>
            <Button size="large" onClick={() => handlePage('cpf')}><MdPerson /> CADASTRAR PESSOA FÍSICA</Button>
            <Button size="large" color="blue" onClick={() => handlePage('cnpj')}><MdBusinessCenter /> CADASTRAR PESSOA JURÍDICA</Button>
          </div>
        </div>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>#</td>
                <td>Nome / Razão Social</td>
                <td>CPF / CNPJ</td>
                <td>Whatsapp</td>
                <td>Cidade</td>
                <td>Estado</td>
                <td>Ação</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TypograhySkeleton col={7} line={10} />
              ) : (
                <>
                  {customers.map(customer => {
                    return (
                      <tr key={customer.id}>
                        <td>{customer.id}</td>
                        <td>{customer.full_name}</td>
                        {customer.fiscal_number.length > 11 ? (
                          <td>
                            <div className="table-icon">
                              <MdBusinessCenter /> {customer.fiscal_number_formatted}
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div className="table-icon">
                              <MdPerson /> {customer.fiscal_number_formatted}
                            </div>
                          </td>
                        )}
                        <td>
                          <div className="table-icon">
                            <FaWhatsapp color="#59B200" /> {customer.phone_whatsapp}
                          </div>
                        </td>
                        <td>{customer.city}</td>
                        <td>{customer.state}</td>
                        <td align="right">
                          <button
                            type="button"
                            className="btn-edit"
                            onClick={() => history.push(`/customer/edit/${customer.fiscal_number}`, customer)}
                          >
                            Editar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </Card>
      </Container>
    </Layout>
  );
}

export default Customers;
