import React from 'react';

import { AuthProvider } from './auth';
import { StateProvider } from './states';
import { NotificationProvider } from './notification';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <StateProvider>
      <NotificationProvider>
        {children}
      </NotificationProvider>
    </StateProvider>
  </AuthProvider>
);

export default AppProvider;
