import React, { useCallback, useEffect, useState } from 'react';

import { MdKeyboardBackspace } from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';

import { Container } from './styles';

interface IUsers {
  id: any;
  name: string;
  email: string;
  active: any;
  created_at: string;
}

const User: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<IUsers[]>([] as IUsers[]);
  const [loadingActive, setLoadingActive] = useState(false);
  const { addToast } = useToasts();
  const { user } = useAuth();

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('users');

      setUsers(response.data.data);
    }

    loadUsers();
  }, []);

  const handleActive = useCallback(async (usuario) => {
    try {
      setLoadingActive(true);
      await api.put(`users/active/${usuario.id}`, {
        active: usuario.active
      });

      const usersMap = users.map(u => ({
        ...u,
        active: u.id === usuario.id ? usuario.active : u.active,
      }));

      addToast('Usuário atualizado.', { appearance: 'success', autoDismiss: true, });
      setLoadingActive(false);
      setUsers(usersMap);
    } catch(err) {
      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }

      setLoadingActive(false);
    }
  }, [addToast, users]);

  return (
    <Layout
      nav="users"
      breadcumb={`Usuários`}
    >
      <Container>
        <div className="top">
          <h2>Usuários</h2>

          <div className="btn">
          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
          <Button
            color="default"
            size="large"
            onClick={() => history.push('/create/user')}
          >
            Criar Usuário
          </Button>
          </div>
        </div>

        <Card>
        <table className="tab table-striped">
            <thead>
              <tr>
                <td>#</td>
                <td>Nome</td>
                <td>Email</td>
                <td>Data Criação</td>
                <td>Ação</td>
              </tr>
            </thead>
            <tbody>
              {users.map(u => {
                return(
                  <tr key={u.id}>
                    <td>{u.id}</td>
                    <td>{u.name}</td>
                    <td>{u.email}</td>
                    <td>{u.created_at}</td>
                    <td>
                      <div className="btn-button">
                        <Button
                          color="primary"
                          onClick={() => history.push(`/user/${u.id}`, u)}
                        >
                          EDITAR
                        </Button>
                        {u.id !== user.id && user.role_id === 2 && u.active && (
                          <Button
                            color="danger" onClick={() => handleActive({ id: u.id, active: false })}
                          >
                            {loadingActive ? 'CARREGANDO...' : 'DESATIVAR'}
                          </Button>
                        )}
                        {u.id !== user.id && user.role_id === 2 && !u.active && (
                          <Button
                            color="default" onClick={() => handleActive({ id: u.id, active: true })}
                          >
                            {loadingActive ? 'CARREGANDO...' : 'ATIVAR'}
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
        </table>
        </Card>
      </Container>
    </Layout>
  );
}

export default User;
