import React, { useState, useEffect, useCallback } from 'react';

import api from '../../../services/api';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button/custom';

import { useStates } from '../../../hooks/states';

import { GridForm, GridButton } from '../styles';

interface IForm {
  form: any;
  handleCep: any;
  handleCnpj?: any;
  handleDeleteOrCancel?: any;
  type: string;
}

const Cnpj: React.FC<IForm> = ({ form, handleCep, handleCnpj, handleDeleteOrCancel, type }) => {
  const [cities, setCities] = useState([]);
  const [state, setState] = useState('');
  const { states } = useStates();

  useEffect(() => {
    if (!state && !form.state) {
      return;
    }

    async function loadCity() {
      const response = await api.get(`tables/cities/${state ? state: form.state}`);

      setCities(response.data);
    }

    loadCity();
  }, [state, form.state]);

  const handleChangeState = useCallback((title) => {
    setState(title);
  }, []);

  return (
    <>
            <GridForm grid={211}>
              <div className="form-group">
                <label>Razão Social <span>*</span></label>
                <Input name="full_name" />
              </div>
              <div className="form-group">
                <label>Nome Fantasia</label>
                <Input name="nickname" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <Input name="email" />
              </div>
            </GridForm>

            <GridForm grid={4}>
              <div className="form-group">
                <label>Data de Abertura <span>*</span></label>
                <Input name="birth" type="date" />
              </div>
              <div className="form-group">
                <label>CNPJ <span>*</span></label>
                <Input
                  name="fiscal_number"
                  mask="cnpj"
                  id="cnpj"
                  onBlur={handleCnpj}
                />
                <div className="detailsCnpj">
                  <small>
                    Situação Cnpj:
                    <span
                      className={form.cadastral_situation === 'ATIVA' ? 'color-active' : 'color-inactive'}
                    >
                      {form.cadastral_situation}
                    </span>
                  </small>
                  <small>Porte: {form.port}</small>
                </div>
              </div>
              <div className="form-group">
                <label>Telefone</label>
                <Input name="phone" mask="phone" id="phone" />
              </div>
              <div className="form-group">
                <label>Celular / Whatsapp <span>*</span></label>
                <Input name="phone_whatsapp" mask="celular" id="celular" />
              </div>
            </GridForm>

            <GridForm grid={1211}>
              <div className="form-group">
                <label>Cep <span>*</span></label>
                <Input name="zip_code" mask="cep" id="cep" onBlur={(event) => handleCep(event.target.value)} />
              </div>
              <div className="form-group">
                <label>Endereço <span>*</span></label>
                <Input name="address" />
              </div>
              <div className="form-group">
                <label>Número <span>*</span></label>
                <Input name="address_number" />
              </div>
              <div className="form-group">
                <label>Complemento</label>
                <Input name="address_complement" />
              </div>
            </GridForm>

            <GridForm grid={3}>
              <div className="form-group">
                <label>Bairro <span>*</span></label>
                <Input name="neighborhood" defaultValue={form.neighborhood} />
              </div>
              <div className="form-group">
              <label>Estado <span>*</span></label>
                <Select name="state" onChange={(event) => handleChangeState(event.target.value)}>
                  <option value="">...</option>
                  {states.map((state: any) => {
                    return (
                      <option
                        key={state.sigla}
                        value={String(state.sigla)}
                        selected={String(state.sigla) === form.state}
                      >
                        {state.nome.toUpperCase()}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div className="form-group">
                <label>Cidade <span>*</span></label>
                <Select name="city">
                    <option value="">...</option>
                    {cities.map((city: any) => {
                      const cityName = city.name.toUpperCase();
                      return (
                        <option
                          key={city.id}
                          value={cityName}
                          selected={cityName === form.city}
                        >
                          {city.name.toUpperCase()}
                        </option>
                      );
                    })}
                </Select>
              </div>
            </GridForm>

            <GridButton>
              <Button type="submit" color="default" size="large">
                {type}
              </Button>

              {type === 'ATUALIZAR' && (
                <Button
                  color="danger"
                  size="large"
                  type="button"
                  onClick={handleDeleteOrCancel}
                >
                  Excluir Cadastro
                </Button>
              )}
            </GridButton>
     </>
  );
};

export default Cnpj;
