import React, { useCallback, useRef, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications';

import { Link, useHistory } from 'react-router-dom';

import logo from '../../assets/logo.svg';

import { useAuth } from '../../hooks/auth';

import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, } from './styles';
import GlobalStyle from '../../styles/global';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const { signIn } = useAuth();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await signIn({
          email: data.email,
          password: data.password,
        });

        setLoading(false);
        history.push('/dashboard');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast('Ops! O email ou senha está incorreto.', { appearance: 'error', autoDismiss: true, });
      }
    },
    [signIn, history, addToast],
  );

  return (
    <>
     <Container>
        <Content>
          <AnimationContainer>
            <img src={logo} alt="App Câmbio" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h3>Faça seu Login</h3>

              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
              />

              <Button type="submit" loading={loading}>Entrar</Button>

              <Link to="/reset-password">Esqueci minha senha</Link>
            </Form>

            <Link to="/">
              <FiLogIn />
              Seja um parceiro
            </Link>
          </AnimationContainer>
        </Content>
      </Container>

      <GlobalStyle theme={true} />
    </>
  );
};

export default SignIn;
