import React, { useState } from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface ISkeleton {
  grid: Number;
}

const Skeletongrid: React.FC<ISkeleton> = ({ grid }) => {
  const [lineArray, ] = useState(() => {
    const array: any = [];

    for (let i = 0; i < grid; i++) {
      array.push(i);
    }
    return array;
  });

  return (
    <>
      {lineArray.map((l: any) => {
        return (
          <SkeletonTheme key={l} color="#2B2C2F" highlightColor="#292A2D">
            <Skeleton height={55} />
          </SkeletonTheme>
        );
      })}
    </>
  );
}

export default Skeletongrid;
