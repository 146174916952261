import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import VMasker from 'vanilla-masker';

import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
  symbol?: string;
  mask?: any;
  precision?: number;
}

const Input: React.FC<InputProps> = ({ name, icon: Icon, symbol = 'R$', mask = 'default', disabled = false, precision = 2, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  if (mask !== 'default') {
    const el = document.getElementById(mask);
    const verifyMaskMoney = !mask.indexOf('money');

    if (el) {
      if (mask === 'celular') {
        VMasker(el).maskPattern("(99) 99999-9999");
      }

      if (mask === 'phone') {
        VMasker(el).maskPattern("(99) 9999-9999");
      }

      if (mask === 'cpf') {
        VMasker(el).maskPattern("999.999.999-99");
      }

      if (mask === 'cnpj') {
        VMasker(el).maskPattern("99.999.999/9999-99");
      }

      if (mask === 'cep') {
        VMasker(el).maskPattern("99.999-999");
      }

      if (verifyMaskMoney) {
        VMasker(el).maskMoney({
          precision: precision,
          separator: ',',
          delimiter: '.',
          unit: symbol,
          zeroCents: false
        });
      }

      if (mask === 'moneyBrl') {
        VMasker(el).maskMoney({
          precision: 2,
          separator: ',',
          delimiter: '.',
          unit: symbol,
          zeroCents: false
        });
      }
    }
  }

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} disabled={disabled} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon size={20} />}

      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        disabled={disabled}
        ref={inputRef}
        {...rest}
      />
    </Container>
  );
};

export default Input;
