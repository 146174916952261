import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface INotification {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding: 0px 14px;
  border-top: 2px solid #3CA372;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
`;

export const Notifications = styled.div<INotification>`
  ${Props => Props.active ? css`
    display: block;
  ` : css`
    display: none;
  `}

  .notification-messages {
      position: absolute;
      z-index: 999;
      top: 50px;
      left: -110px;
      width: 280px;
      border-radius: 4px;
      background: #424242;
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      padding: 8px;

      #triangulo-para-cima {
        display: block;
        position: absolute;
        top: -12px;
        left: 46%;
        width: 0;
        height: 0;
        z-index: 99;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #424242;
      }

      ul {
        list-style: none;

        li {
          display: flex;
          flex-direction: column;
          background: #363636;
          padding: 6px;
          border-radius: 3px;
          transition: background-color 0.2s;

          &:hover {
            background: ${shade(0.4, '#363636')};
          }

          & + li {
            margin-top: 2px;
          }

          h4 {
            font-size: 12px;
          }

          span {
            font-size: 11px;
            margin: 0px;
            padding: 0px;
          }

          small {
            font-size: 10px;
            color: #ccc;
          }
        }
      }
    }
`;

export const User = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 28px;
  }

  .notification {
    position: relative;
    padding-right: 8px;

    button {
      border: none;
      background: none;

      svg {
        color: #fff;
      }
    }
  }

  .avatar {
    display: flex;
    flex-direction: column;
    text-align: right;
    border-left: 1px solid #333333;
    padding-left: 16px;

    small {
      font-size: 9px;
    }
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 5px;
  }
`;
