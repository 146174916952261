import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';

import Customers from '../pages/Customers';
import CustomerCreate from '../pages/Customers/create';
import CustomerEdit from '../pages/Customers/edit';

import Sales from '../pages/Sales';
import DetailSale from '../pages/Sales/details';

import Profile from '../pages/Profile';

import Users from '../pages/Users';
import UserEdit from '../pages/Users/edit';
import UserCreate from '../pages/Users/create';

import Settings from '../pages/Settings';
import SettingExchange from '../pages/Settings/Exchange';
import EditExchange from '../pages/Settings/Exchange/edit';

import Rates from '../pages/Rates';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/rates/:qrcode" component={Rates} />

    <Route path="/dashboard" component={Dashboard} isPrivate />

    <Route path="/customers" component={Customers} isPrivate />
    <Route path="/customer/create" component={CustomerCreate} isPrivate />
    <Route path="/customer/edit/:fiscalNumber" component={CustomerEdit} isPrivate />

    <Route path="/sales" component={Sales} isPrivate />
    <Route path="/sale/:id" component={DetailSale} isPrivate />

    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/users" component={Users} isPrivate />
    <Route path="/edit/user/:id" component={UserEdit} isPrivate />
    <Route path="/create/user" component={UserCreate} isPrivate />

    <Route path="/settings" component={Settings} isPrivate />
    <Route path="/setting/exchange" component={SettingExchange} isPrivate />
    <Route path="/setting/edit/exchange" component={EditExchange} isPrivate />
  </Switch>
);

export default Routes;
