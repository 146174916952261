import styled, { css } from 'styled-components';

interface IProp {
  isFlex?: boolean;
  color?: any;
}

export const Container = styled.div`
  display: block;
  width: 100%;
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  .footer {
    display: block;
    width: 100%;
    z-index: 9;
    text-align: right;
    margin-bottom: 12px;
    font-size: 11px;
    margin-top: 12px;
  }
`;

export const Card = styled.div<IProp>`
  ${Props => Props.isFlex ? css`
    display: flex;
  ` : css`
    display: block;
  `}
  position: relative;

  svg {
    color #eee;
  }

  background: ${Props => Props.color};
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  color: #AAAAAA;

  .titleCard {
    background: #555555;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;
