import React, { useCallback, useRef, useState } from 'react';

import { MdKeyboardBackspace } from 'react-icons/md';

import { useToasts } from 'react-toast-notifications';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useHistory } from 'react-router-dom';

import getValidationErrors from '../../utils/getValidationErrors';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import Input from '../../components/Input';
import Select from '../../components/Select';

import { Container, GroupButton, Grid } from './styles';

const Create: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome Completo obrigatório'),
        email: Yup.string().required('Email Obrigatório'),
        password: Yup.string().required('Senha Obrigatório'),
        role_id: Yup.string().required('Permissão Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('users', data);

      addToast('Usuário cadastrado com sucesso.', { appearance: 'success', autoDismiss: true, });
      history.push(`/users`);
    } catch(err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        setLoading(false);

        return;
      }

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }
      setLoading(false);
    }
  }, [addToast, history]);

  return (
    <Layout
      nav="users"
      breadcumb={`Usuário,Criar`}
      loading={loading}
    >
      <Container>
        <div className="top">
          <h2>Usuário / Criar</h2>

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Card>
            <Grid>
                <div>
                    <p>Nome:</p>
                    <Input name="name" />
                  </div>
                <div>
                  <p>Email:</p>
                  <Input name="email" />
                </div>
                <div>
                  <p>Permissão:</p>
                  <Select name="role_id">
                    <option value="">...</option>
                    <option value="2">ADMINISTRADOR</option>
                    <option value="3">OPERADOR</option>
                  </Select>
                </div>
                <div>
                  <p>Alterar Senha:</p>
                  <Input type="text" name="password" />
                </div>
            </Grid>
          </Card>

          <GroupButton>
            <Button type="submit" color="default" size="large">
              ADICIONAR
            </Button>
          </GroupButton>
        </Form>
      </Container>
    </Layout>
  );
}

export default Create;
