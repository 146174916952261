import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import { MdKeyboardBackspace } from 'react-icons/md';
import Flag from 'react-flagkit';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import CheckboxGroup from 'react-checkbox-group'

import Layout from '../../../components/Layout';
import Button from '../../../components/Button/custom';

import { Container, Grid, GridButton } from './styles';

interface ICoins {
  id: any;
  name: string;
  icon: string;
  coin: string;
}

const EditExchange: React.FC = () => {
  const history = useHistory();
  const [checkbox, setCheckbox] = useState<string[]>([]);
  const [secCheckbox, setSecCheckbox] = useState<string[]>([]);
  const [coins, setCoins] = useState<ICoins[]>([] as ICoins[]);
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    async function loadCheckbox() {
      const response = await api.get('coins');
      setCoins(response.data);

      const responseRates = await api.get('rates');
      const idCheckbox = responseRates.data.map((rate: any) => rate.coin_id);
      setCheckbox(idCheckbox)
      setSecCheckbox(idCheckbox);
      setLoading(false);
    }

    loadCheckbox();
  }, []);

  const handleSubmit = useCallback(() => {
    const lastCheckbox = checkbox.slice(-1)[0];

    if (checkbox.length === secCheckbox.length) {
      return;
    }

    if (checkbox.length < secCheckbox.length) {
      handleUpdate();
      return;
    }

    setLoading(true);
    checkbox.forEach(async (check) => {
      await api.post('rates', {
        rate_buy: 0,
        rate_sale: 0,
        coin_id: check
      });

      if (check === lastCheckbox) {
        setLoading(false);
        addToast('Atualizado com sucesso.', { appearance: 'success', autoDismiss: true, });
        history.push('/setting/exchange');
      }
    });
  }, [secCheckbox, checkbox, addToast, history]);

  const handleUpdate = useCallback(() => {

    const filterCheckbox = secCheckbox.filter((check) => {
      return checkbox.indexOf(check) === -1;
    });

    const lastFilterCheckbox = filterCheckbox.slice(-1)[0];

    setLoading(true);
    filterCheckbox.forEach(async (check) => {
      await api.delete(`rates/${check}`);

      if (check === lastFilterCheckbox) {
        setLoading(false);
        addToast('Atualizado com sucesso.', { appearance: 'success', autoDismiss: true, });
        history.push('/setting/exchange');
      }
    });
  }, [secCheckbox, checkbox, addToast, history]);

  return (
    <Layout
      nav="settings"
      breadcumb={`Cotações de Câmbio,Configuração de Moedas`}
      loading={loading}
    >
      <Container>
        <div className="top">
          <h2>Cotações de Câmbio / Configurar Moedas</h2>

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>

          <CheckboxGroup name="rates" value={checkbox} onChange={setCheckbox}>
            {(Checkbox) => (
              <Grid>
                {coins.map(coin => {
                  return(
                    <div key={coin.id} className="input-exchange">
                      <div className="icon-flag-big">
                        <Flag country={String(coin.icon)} size={75} style={{ marginTop: -10, marginLeft: -10 }} />
                      </div>
                      <p>{coin.name}</p>
                      <label>
                        <Checkbox value={coin.id} />
                      </label>
                    </div>
                  );
                })}
              </Grid>
            )}
          </CheckboxGroup>

          <GridButton>
          <Button type="submit" color="default" size="large" onClick={handleSubmit}>
            Atualizar
          </Button>
        </GridButton>
      </Container>
    </Layout>
  );
}

export default EditExchange;
