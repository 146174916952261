import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IButton {
  color: 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'blue' | 'gray' | 'orange';
  size: 'small' | 'large' | 'default';
  outline: boolean;
}

export const Container = styled.button`
  background: #3CA372;
  height: 46px;
  border-radius: 10px;
  border: 0;
  padding: 0 12px;
  color: #eee;
  width: 100%;
  font-weight: 500;
  margin-top: 12px;
  transition: background-color 0.2s ease-in-out;

  button {
    background: none;
    border: none;
    color: #fff;
  }

  &:hover {
    background: ${shade(0.2, '#3CA372')};
  }
`;

export const Button = styled.button<IButton>`
  ${Props => Props.color === 'default' && css`
    background: #3CA372;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#3CA372')};
    }
  `}
  ${Props => Props.color === 'primary' && css`
    background: #0080FF;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#0080FF')};
    }
  `}
  ${Props => Props.color === 'secondary' && css`
    background: #EEEEEE;
    border: 0px;
    color: #000;

    &:hover {
      background: ${shade(0.4, '#EEEEEE')};
    }
  `}
  ${Props => Props.color === 'danger' && css`
    background: #FF4D4D;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#FF4D4D')};
    }
  `}
  ${Props => Props.color === 'warning' && css`
    background: #FFA64D;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#FFA64D')};
    }
  `}
  ${Props => Props.color === 'blue' && css`
    background: #0080FF;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#0080FF')};
    }
  `}
  ${Props => Props.color === 'gray' && css`
    background: #2b2c2f;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#2b2c2f')};
    }
  `}
  ${Props => Props.color === 'orange' && css`
    background: #FF7A4D;
    border: 0px;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#FF7A4D')};
    }
  `}

  ${Props => Props.size === 'small' && css`
    padding: 2px 6px;
    border: 0px;
    font-size: 12px;
  `}
  ${Props => Props.size === 'large' && css`
    padding: 11px 10px;
    border: 0px;
    font-size: 14px;
  `}
  ${Props => Props.size === 'default' && css`
    padding: 4px 8px;
    border: 0px;
    font-size: 12px;
  `}

  transition: background-color 0.2s ease-in-out;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 2px;
  }
`;

export const ButtonOutline = styled.button<IButton>`
  ${Props => Props.color === 'default' && css`
    border: 4px solid #3CA372;
    color: #fff;
  `}
  ${Props => Props.color === 'primary' && css`
    border: 1px solid #0080FF;
    color: #fff;
  `}
  ${Props => Props.color === 'secondary' && css`
    border: 1px solid #EEEEEE;
    color: #fff;
  `}

  ${Props => Props.size === 'small' && css`
    padding: 2px 6px;
    border: 0px;
    font-size: 12px;
  `}
  ${Props => Props.size === 'large' && css`
    padding: 11px 10px;
    border: 0px;
    font-size: 14px;
  `}
  ${Props => Props.size === 'default' && css`
    padding: 4px 8px;
    border: 0px;
    font-size: 12px;
  `}

  border-radius: 4px;
  background: none;
  border: 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 2px;
  }
`;
