import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  justify-content: center;
`;

const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to {
  opacity: 1;
  transform: translateX(0);
}
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  img {
    width: 180px;
  }

  form {
    margin: 20px;
    width: 340px;
    text-align: center;
    h3 {
      margin-bottom: 18px;
      color: #eee;
    }
    a {
      color: #eee;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: ${shade(0.2, '#eee')};
      }
    }
  }
  > a {
    color: #eee;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#eee')};
    }
  }
`;
