import React from 'react';

import { Link } from 'react-router-dom';

import {
  MdDashboard,
  MdAttachMoney,
  MdShoppingCart,
  MdSettings,
  MdPerson
} from "react-icons/md";

import { BiLogOutCircle } from 'react-icons/bi';

import { Container, Content, Logo, Nav } from './styles';

import logo from '../../assets/logo.svg';

import { useAuth } from '../../hooks/auth';

interface IActive {
  nav: String;

}

const Navigation: React.FC<IActive> = ({ nav }) => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Content id="navigation-nav">
        <Logo>
          <img src={logo} alt="App Câmbio" />
        </Logo>

        <Nav>
          <li className={nav === '' ? 'active' : ''}><Link to="/dashboard"><span><MdDashboard /></span> Dashboard</Link></li>
          <li className={nav === 'customers' ? 'active' : ''}><Link to="/customers"><span><MdShoppingCart /></span> Clientes</Link></li>
          <li className={nav === 'sales' ? 'active' : ''}><Link to="/sales"><span><MdAttachMoney /></span> Vendas</Link></li>
          <li className={nav === 'users' ? 'active' : ''}><Link to="/users"><span><MdPerson /></span> Usuários</Link></li>
          <li className={nav === 'settings' ? 'active' : ''}><Link to="/settings"><span><MdSettings /></span> Configurações</Link></li>
        </Nav>

        <button type="button" onClick={() => signOut()} className="signout" title="sair do sistema">
          <BiLogOutCircle size={22} />
        </button>
      </Content>
    </Container>
  );
};

export default Navigation;
