import styled, { css } from 'styled-components';

interface IProps {
  open: boolean;
}

export const Body = styled.div<IProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);

  ${Props => Props.open ? css`
    display: flex;
  ` : css`
    display: none;
  `}
`;

export const Container = styled.div`
  h4 {
    font-size: 26px;
    text-align: center;
  }

  .bloco {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    margin-top: 12px;

    button + button {
      margin-left: 6px;
    }
  }

  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  background: #424242;
  border-radius: 5px;
  padding: 12px;
`;
