import React, { useState, useRef, useCallback } from 'react';

import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import { stringNumber, formatDateFromBrforUs } from '../../utils/helpers';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';
import CpfForm from './include/cpf';
import CnpjForm from './include/cnpj';

import { Container } from './styles';

interface IEvent {
  sex: string;
  full_name: string;
  nickname: string;
  civil_status: string;
  exterior: string;
  name_dad: string;
  name_mother: string;
  identity_id: string;
  identity_emission: Date;
  identity_state: string;
  identity_organ: string;
  passport_number: string;
  passport_emission: Date;
  passport_nation: string;
  email: string;
  phone: string;
  phone_whatsapp: string;
  fiscal_number: string;
  birth: Date;
  zip_code: string;
  address: string;
  address_number: string;
  complement: string;
  naturalness: string;
  nationality: string;
  neighborhood: string;
  state: string;
  city: string;
}

const Create: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToasts();
  const url = new URL(String(window.location));
  const params = new URLSearchParams(url.search);
  const type = params.get('type') || '';

  const handleSubmit = useCallback(async (data: IEvent) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);

      if (type === 'cnpj') {
        const schema = Yup.object().shape({
          full_name: Yup.string().required('Nome Completo obrigatório'),
          birth: Yup.string().required('Nascimento obrigatória'),
          fiscal_number: Yup.string().required('CPF obrigatória'),
          phone_whatsapp: Yup.string().required('Celular obrigatória'),
          zip_code: Yup.string().required('CEP obrigatória'),
          address: Yup.string().required('Endereço obrigatória'),
          address_number: Yup.string().required('Número do endereço obrigatória'),
          neighborhood: Yup.string().required('Bairro obrigatória'),
          state: Yup.string().required('Estado obrigatória'),
          city: Yup.string().required('Cidade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } else {
        const schema = Yup.object().shape({
          full_name: Yup.string().required('Nome Completo obrigatório'),
          birth: Yup.string().required('Nascimento obrigatória'),
          sex: Yup.string().required('Sexo obrigatória'),
          civil_status: Yup.string().required('Estado Civil obrigatória'),
          name_mother: Yup.string().required('Nome da mãe obrigatória'),
          fiscal_number: Yup.string().required('CPF obrigatória'),
          identity_id: Yup.string().required('RG Número obrigatória'),
          phone_whatsapp: Yup.string().required('Celular obrigatória'),
          zip_code: Yup.string().required('CEP obrigatória'),
          address: Yup.string().required('Endereço obrigatória'),
          address_number: Yup.string().required('Número do endereço obrigatória'),
          neighborhood: Yup.string().required('Bairro obrigatória'),
          state: Yup.string().required('Estado obrigatória'),
          city: Yup.string().required('Cidade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      const { cadastral_situation, port } = formData;

      const response = await api.post('customers', {
        ...data,
        type,
        cadastral_situation: cadastral_situation || '',
        port: port || ''
      });

      if (response.data.error) {
        addToast(response.data.error, { appearance: 'warning', autoDismiss: true, });
        return;
      }

      addToast('Cliente cadastrado com sucesso.', { appearance: 'success', autoDismiss: true, });
      history.push(`/customers`, response.data);
      setLoading(false);

    } catch(err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        setLoading(false);

        return;
      }

      if (err.response.data.error) {
        addToast(err.response.data.error, { appearance: 'error', autoDismiss: true, });
      } else {
        addToast('Ops! Algo deu errado', { appearance: 'error', autoDismiss: true, });
      }

      setLoading(false);
    }
  }, [history, addToast, type, formData]);

  const handleCep = useCallback(async (event: any) => {
    const cep = stringNumber(event);
    if (cep.length < 8) {
      return;
    }

    const response = await api.get(`cep/${cep}`);

    setFormData({
      ...formData,
      address: response.data.address,
      neighborhood: response.data.district,
      state: response.data.state
    });
  }, [formData]);

  const handleCnpj = useCallback(async (event) => {
    const cnpj = stringNumber(event.target.value);

    try {
      setLoading(true);
      const { data } = await api.get(`stores/cnpj?cnpj=${cnpj}`);

      if (data.status === 'ERROR') {
        addToast(data.message, { appearance: 'warning', autoDismiss: true, });
      } else {
        setFormData({
          ...formData,
          full_name: data.nome,
          phone: data.telefone,
          email: data.email,
          nickname: data.fantasia,
          cadastral_situation: data.situacao,
          neighborhood: data.bairro,
          address: data.logradouro,
          address_number: data.numero,
          address_complement: data.complemento,
          zip_code: data.cep,
          city: data.municipio,
          state: data.uf,
          birth: formatDateFromBrforUs(data.data_situacao),
          port: data.porte
        });
      }

      setLoading(false);
    } catch(err) {
      setLoading(false);
    }
  }, [addToast, formData]);

  return (
    <Layout nav="customers" breadcumb="Clientes,Criar" loading={loading}>
      <Container>
        <div className="top">
          {type === 'cnpj' ? (
            <h2>Cadastro de Cliente - Pessoa Jurídica</h2>
          ) : (
            <h2>Cadastro de Cliente - Pessoa Física</h2>
          )}

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>
        <Card>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
            {type === 'cnpj' ? (
              <CnpjForm
                form={formData}
                handleCep={handleCep}
                handleCnpj={handleCnpj}
                type="CADASTRAR"
              />
            ) : (
              <CpfForm
                form={formData}
                handleCep={handleCep}
                type="CADASTRAR"
              />
            )}
          </Form>
        </Card>
      </Container>
    </Layout>
  );
};

export default Create;
