import React, { ButtonHTMLAttributes } from 'react';

import { Button, ButtonOutline } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'blue' | 'gray' | 'orange';
  size?: 'small' | 'large' | 'default';
  outline?: boolean;
};

const Custom: React.FC<ButtonProps> = (
  { children,
    loading,
    color = 'default',
    size = 'default',
    outline = false,
    ...rest }
  ) => {
    return (
        <>
          {outline ? (
            <ButtonOutline color={color} size={size} outline={outline} {...rest}>
              {loading ? 'Carregando...' : children}
            </ButtonOutline>
          ) : (
            <Button color={color} size={size} outline={outline} {...rest}>
              {loading ? 'Carregando...' : children}
            </Button>
          )}

        </>
    );
}

export default Custom
