import styled, { css } from 'styled-components';

interface IProps {
  grid: Number;
}

export const Container = styled.div`
  display: block;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #2B2C2F;
    padding-top: 12px;
    padding-bottom: 12px;

    .search {
      display: flex;
      flex: 1;
      width: 100%;

      form {
        width: 96%;
      }
    }

    .btn {
      display: flex;

      button + button {
        margin-left: 5px;
      }
    }
  }
`;

export const GridForm = styled.div<IProps>`
  ${Props => Props.grid === 21 && css`
      grid-template-columns: 2fr 1fr;
  `}
  ${Props => Props.grid === 2 && css`
      grid-template-columns: 1fr 1fr;
  `}
  ${Props => Props.grid === 211 && css`
      grid-template-columns: 2fr 1fr 1fr;
  `}
  ${Props => Props.grid === 1211 && css`
      grid-template-columns: 1fr 2fr 1fr 1fr;
  `}
  ${Props => Props.grid === 3 && css`
      grid-template-columns: 1fr 1fr 1fr;
  `}
  ${Props => Props.grid === 4 && css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
  ${Props => Props.grid === 5 && css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `}

  display: grid;
  grid-gap: 10px;
  margin-bottom: 10px;

  .form-group {
    display: flex;
    flex-direction: column;

    .detailsCnpj {
        display: flex;
        margin: 0px;

        small {
          font-size: 11px;
          padding-left: 6px;
          padding-top: 4px;

          .color-active {
            color: #80FF00;
          }

          .color-inactive {
            color: #FF9326;
          }
        }
    }

    button {
      border: none;
      background: none;
      color: #fff;
      font-size: 12px;
      margin-left: 4px;
    }

    label {
      margin-left: 4px;
      margin-bottom: 4px;

      span {
        color: #FF4D4D;
      }
    }
  }
`;

export const GridButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;

  button + button {
    margin-top: 12px;
  }
`;
