import React, { useEffect, useState } from 'react';

import { MdKeyboardBackspace, MdAttachMoney } from 'react-icons/md';

import Flag from 'react-flagkit';

import { useLocation, useHistory, Link } from 'react-router-dom';

import api from '../../services/api';

import Layout from '../../components/Layout';
import Card from '../../components/Layout/card';
import Button from '../../components/Button/custom';

import { Container, Grid, Content, Icon, IconCoin, GridDetail, Links } from './styles';

interface ISale {
  id: any;
  coin_id: number;
  tax_coin: number;
  buy_coin: number;
  sale: number;
  type_sale: string;
  customer_id: number;
  tax_coin_formatted: string;
  buy_coin_formatted: string;
  sale_formatted: string;
  customer: any;
  coin: {
    id: number;
    name: string;
    icon: string;
  }
}

const Details: React.FC = () => {
  const location = useLocation();
  const state = Object(location.state);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [sale, setSale] = useState<ISale>({} as ISale);

  useEffect(() => {
    async function loadSale() {
      const response = await api.get(`sales/${state.id}`);

      setLoading(false);

      setSale(response.data);
    }

    loadSale();
  }, [state.id]);

  return (
    <Layout
      nav="sales"
      breadcumb={`Vendas,Detalhes,${sale.coin ? sale.coin.name : state.coin.name}`}
      loading={loading}
    >
      <Container>
        <div className="top">
          <h2>Detalhes da Venda / {sale.customer ? sale.customer.full_name : state.customer.full_name}</h2>

          <Button
            color="gray"
            size="large"
            onClick={() => history.goBack()}
          >
            <MdKeyboardBackspace /> Voltar
          </Button>
        </div>

        <Grid>
          <Card>
            <Content>
              <IconCoin>
                {sale.coin && (
                  <Flag country={String(sale.coin.icon)} size={65} style={{ marginTop: -10, marginLeft: -10 }} />
                )}
              </IconCoin>

              <div className="bloco">
                <small>Taxa da Moeda ({sale.coin ? sale.coin.name : ''})</small>
                <div><span>R$</span><h3>{sale.tax_coin_formatted}</h3></div>
              </div>
            </Content>
          </Card>
          <Card>
            <Content>
              <IconCoin>
                {sale.coin && (
                  <Flag country={String(sale.coin.icon)} size={65} style={{ marginTop: -10, marginLeft: -10 }} />
                )}
              </IconCoin>

              <div className="bloco">
                <small>Quantidade de Moeda</small>
                <div><span>{sale.coin ? sale.coin.icon : ''}</span><h3>{sale.buy_coin_formatted}</h3></div>
              </div>
            </Content>
          </Card>
          <Card>
            <Content>
              <Icon bgcolor="#3CA372">
                <MdAttachMoney />
              </Icon>

              <div className="bloco">
                <small>Total pago</small>
                <div><span>R$</span><h3>{sale.sale_formatted}</h3></div>
              </div>
            </Content>
          </Card>
        </Grid>

        <Card>
          <GridDetail>
            <div className="detail_info">
              <span>
                <strong>Nome: </strong>{sale.customer && sale.customer.full_name}
              </span>
            </div>
            <div className="detail_info">
              <span>
                <strong>Cpf ou Cnpj: </strong>{sale.customer && sale.customer.fiscal_number}
              </span>
            </div>
            <div className="detail_info">
              <span>
                <strong>Whatsapp: </strong>{sale.customer && sale.customer.phone_whatsapp}
              </span>
            </div>
            <div className="detail_info">
              <span>
                <strong>Endereço: </strong>
                {sale.customer && sale.customer.address}, {sale.customer && sale.customer.address_number} - {sale.customer && sale.customer.address_complement}
              </span>
            </div>
            <div className="detail_info">
              <span>
                <strong>Cidade: </strong>{sale.customer && sale.customer.city}
              </span>
            </div>
            <div className="detail_info">
              <span>
                <strong>Estado: </strong>{sale.customer && sale.customer.state}
              </span>
            </div>
          </GridDetail>
        </Card>

        <Links>
          <Link to="/" className="link-details">
            Visualizar Recibo
          </Link>
          <button type="button" className="btn-delete-large marginTop">Cancelar</button>
        </Links>
      </Container>
    </Layout>
  );
}

export default Details;
