import React, { ButtonHTMLAttributes } from 'react';

import { Card } from './styles';

type CardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isFlex?: boolean;
  color?: String;
};

const CardContainer: React.FC<CardProps> = ({ children, isFlex, color = '#2b2c2f' }) => {
  return (
    <Card isFlex={isFlex} color={color}>
      {children}
    </Card>
  );
};

export default CardContainer;
