import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;

  .btn-button {
    display: flex;

    button + button {
      margin-left: 5px;
    }
  }

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #2B2C2F;
    padding-top: 12px;
    padding-bottom: 12px;

    .search {
      display: flex;
      flex: 1;
      width: 100%;

      form {
        width: 96%;
      }
    }

    .btn {
      display: flex;

      button + button {
        margin-left: 5px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;

  p {
    padding-left: 8px;
    padding-bottom: 6px;
  }
`;

export const GroupButton = styled.div`
  display: block;
  width: 100%;
  padding-top: 14px;

  button {
    width: 100%;

    & + button {
      margin-top: 12px;
    }
  }
`;
