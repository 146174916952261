import { createGlobalStyle, css } from 'styled-components';
import { shade } from 'polished';

interface IProps {
  theme?: boolean;
}

export default createGlobalStyle<IProps>`
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  * {
    margin: 0;
    padding:0;
    outline: 0;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
      background: rgba(255,255,255,0.2);
  }

  a {
    text-decoration: none;
    color: ${Props => Props.theme ? '#ccc' : '#333333'};
  }
  body {
    background: ${Props => Props.theme ? '#1a1a1b' : '#eee'};
    color: ${Props => Props.theme ? '#eee' : '#333333'};
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }
  h1, h2, h3 h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  table {
    border-collapse: collapse;
    flex: 1;
  }
  select option {
    font-size: 16px;
  }
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    font-size: 12px;
    width: 100%;

    .table-icon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    }
  }
  thead {
    font-weight: bold;
    color: ${Props => Props.theme ? '#BFB6BD' : '#333333'};
    font-size: 14px;
  }
  thead tr td {
    padding: 10px;
  }
  tbody tr td {
    padding: 10px;
    border-top: 1px solid #1a1a1b;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .titleCard {
    font-size: 15px;
    font-weight: 400;
    color: #F4F4F4;
  }

  #navigation-nav {
    ${Props => Props.theme ? css`
      background: #1c1d1f;
      background-image: linear-gradient(to bottom, #1c1d1f, #333333 130%);
    ` : css`
      background: #3CA372;
      background-image: linear-gradient(to bottom, #3CA372, #30835B 130%);
    `}
  }

  .icon-flag-big {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #eee;
  }

  .icon-flag {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      overflow: hidden;
      border: 2px solid #eee;
  }

  .icon-flag-small {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      border: 2px solid #eee;
  }

  .switch {
    width: auto;
  }

  .btn-details {
    display: block;
    padding: 5px 8px;
    background: #111111;
    color: #F4F4F4;
    border: 0px;
    border-radius: 4px;
    font-size: 12px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.4, '#2222')}
    }
  }

  .btn-edit {
    display: block;
    padding: 5px 8px;
    background: #3CA372;
    color: #F4F4F4;
    border: 0px;
    border-radius: 4px;
    font-size: 12px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.4, '#3CA372')}
    }
  }

  .btn-delete {
    display: block;
    padding: 5px 8px;
    background: #FF4D4D;
    color: #F4F4F4;
    border: 0px;
    border-radius: 4px;
    font-size: 12px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.4, '#FF4D4D')}
    }
  }

  .detail_info {
    display: flex;
    flex-direction: column;

    strong {
      margin-right: 5px;
      font-weight: bold;
    }

    span {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
      background: #222;
      height: 42px;
      border-radius: 6px;
    }
  }

  .marginTop {
    margin-top: 8px;
  }

  a.link-details {
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background: #3CA372;
    border-radius: 6px;
    transition: background-color 0.2s;
    color: #fff;

    &:hover {
      background: ${shade(0.4, '#3CA372')}
    }
  }

  .btn-delete-large {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #FF4D4D;
    color: #F4F4F4;
    border: 0px;
    border-radius: 6px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.4, '#FF4D4D')}
    }
  }

  ul.navigation-edit {
    display: flex;
    flex: 1;
    list-style: none;
    padding-left: 15px;

    button.active {
      background: #3F4145;
    }

    button {
      background: #2B2C2F;
      border: 0;
      color: #fff;
      padding: 12px 14px;

      &:hover {
        background: #3F4145;
      }
    }
  }

  .grid-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2B2C2F;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    height: 220px;

    div {
      text-align: center;
    }

    p {
      color: #fff;
      font-size: 14px;
    }

    &:hover {
      background: ${shade(0.2, '#2B2C2F')}
    }
  }

  .section {
    display: block;

    aside {
      margin-top: 12px;

      ul {
        list-style: none;
        margin-top: 6px;

        li {
          display: flex;
          justify-content: space-between;
          list-style: none;
          width: 100%;
          background: #3F4145;
          border-radius: 4px;
          padding: 4px 8px;

          a {
            background: #eee;
            border-radius: 4px;
            padding: 4px 6px;
            font-size: 11px;
            color: #000;

            &:hover {
              background: #3F4145;
              color: #fff;
            }
          }

          & + li {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .input-exchange {
    background: #2B2C2F;

    input {
      background: #1F2021;
      color: #eee;
    }
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2B2C2F;
    border-radius: 5px;
    border: 2px dashed #ccc;
    border-image: none;
    width: 100%;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer {
    color: ${Props => Props.theme ? '#ccc' : '#333333'};
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 50px;
    margin-top: -24px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
