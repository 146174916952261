import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { MdDashboard } from 'react-icons/md';

interface IBreadcumb {
  breadcumb: String;
}

const SimpleBreadcrumbs: React.FC<IBreadcumb> = ({ breadcumb }) => {
  const [breadcumbs, ] = useState(() => {
    const verifyStringBreadcumb = breadcumb.includes(',');

    let breadcumbArray: any = [];

    if (verifyStringBreadcumb) {
      breadcumbArray = breadcumb.split(',');
    }

    return breadcumbArray;
  });

  return (
    <div style={{ color: '#ccc', display: 'flex', alignItems: 'center' }}>
      <MdDashboard style={{ marginRight: '4px' }} />
      <Breadcrumbs aria-label="breadcrumb" color="initial">
        <Typography color="initial">Dashboard</Typography>

        {breadcumbs.length ? breadcumbs.map((bread: any) => {
          return(
            <Typography key={bread} color="initial">{bread}</Typography>
          );
        }) : <Typography color="initial">{breadcumb}</Typography>}
      </Breadcrumbs>
    </div>
  );
}

export default SimpleBreadcrumbs;
